<template>
  <div class="contents">
    <v-card v-if="activeMissions.length > 0" class="card pa-4" elevation="3" outlined>
      <v-row justify="center">
        <v-card-title align-center
          >{{ $t('activeMissions') }}
          <v-btn small text right class="ml-5 btnShowAll" @click="openMissions">
            {{ $t('showAll') }} ({{ activeMissionsCount }})
          </v-btn>
        </v-card-title>
      </v-row>

      <div v-if="activeMissions.length > 0" class="d-inline d-sm-flex justify-space-around my-7">
        <div v-for="(mission, i) in activeMissions" :key="i">
          <div v-if="mission.user" class="d-flex flex-column">
            <mission-countdown
              :name="mission.user.name"
              :photo="mission.user.profilePhoto ? mission.user.profilePhoto : undefined"
              :end-time="mission.endTime"
            />
          </div>
        </div>
      </div>
    </v-card>
    <v-card v-else class="card pa-4" elevation="3" outlined>
      <v-row justify="center">
        <v-card-title>{{ $t('noActiveMissions') }}</v-card-title>
      </v-row>
      <p class="ma-4">{{ $t('emptyDashMissions') }}</p>
      <v-img
        class="mission-icon-image mx-auto"
        src="@/assets/images/alert-types/missionAlarm2.svg"
        @click="openMissions"
      />
    </v-card>
  </div>
</template>

<script>
import MissionCountdown from '@/components/MissionCountdown.vue';

export default {
  name: 'DashMissions',

  components: {
    MissionCountdown,
  },
  props: {
    activeMissions: {
      type: Array,
      required: false,
      default: () => [],
    },
    activeMissionsCount: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  methods: {
    openMissions() {
      this.$router.push('/missions/');
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 12px;
}
.mission-icon-image {
  filter: opacity(30%);
  width: 130px;
  max-width: 130px;
  height: 130px;
  max-height: 130px;
  margin-top: 25px !important;
  margin-bottom: 20px !important;
  cursor: pointer;
}
.btnShowAll {
  position: absolute;
  right: 8px;
  margin-top: 3px;
}
</style>
