<template>
  <div
    class="c-async profile-photo"
    :class="{ asyncWrapperClass, 'light-border': lightBorder, clickable }"
    :style="{ width: size, height: size, borderRadius: size }"
  >
    <v-img
      :src="src"
      :alt="userName"
      :width="size"
      :height="size"
      @load="onLoad"
      @error="onError"
      @click="onClick"
    />
    <div class="c-async-loading-icon">
      <v-icon light> fa-cloud-download-alt </v-icon>
    </div>
  </div>
</template>

<script>
import { emptyStateImage } from '@/lib/ui/profilePhoto';
import imageDialog from '@/components/dialog/imageDialog';

export default {
  name: 'ProfilePhoto',

  props: {
    url: {
      type: String,
      required: false,
      default: emptyStateImage,
    },

    userName: {
      type: String,
      required: false,
      default: '',
    },

    clickable: {
      type: Boolean,
      required: false,
      default: true,
    },

    size: {
      type: String,
      required: false,
      default: '40px',
    },

    lightBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    src: emptyStateImage,
    loading: true,
    success: false,
  }),

  computed: {
    asyncWrapperClass() {
      if (this.src === emptyStateImage) {
        return '';
      } else if (this.loading) {
        return 'loading';
      } else if (this.success && this.clickable) {
        return 'clickable';
      } else {
        return '';
      }
    },
  },

  watch: {
    //Profile photo urls contains userid,guids and timestamps, so we need to check if
    //the base url + userid has changed has changed.
    //Only update the image if the base url + userid has changed
    url(newUrl, oldUrl) {
      let oldPath = oldUrl?.split('?')[0];
      let newPath = newUrl?.split('?')[0];
      if (oldPath !== newPath) this.setImageSource();
    },
  },

  created() {
    this.setImageSource();
  },

  methods: {
    onLoad() {
      if (this.src !== emptyStateImage) {
        this.loading = false;
        this.success = true;
      }
    },

    onError() {
      this.src = emptyStateImage;
      this.loading = false;
      this.success = false;
    },

    onClick() {
      if (!this.loading && this.success && this.clickable) {
        imageDialog(this.src, this.userName).open();
      }
    },
    setImageSource() {
      this.src = this.url === '' || this.url == null ? emptyStateImage : this.url;
    },
  },
};
</script>
