<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card width="100%">
    <div class="alertTitleBox" :class="[alertTypeClass]">
      <v-card-title class="justify-space-between py-0">
        {{ data.name }}
        <img
          class="ma-2 ml-6 pt-2"
          :src="emblem"
          width="40"
          min-width="40"
          height="40"
          min-height="40"
        />
      </v-card-title>
      <v-card-subtitle>
        {{ data.description }}
      </v-card-subtitle>
    </div>
    <!-- <v-card-text class="d-flex flex-column align-start pb-0">
      <v-row justify="space-around" no-gutters>
        <v-col>
          <v-chip class="my-1 ">
            <v-img :src="alertTypeImage[data.alertType]" max-width="30px" class="ml-n3 mr-1" />
            {{ $t(alertTypeDisplayName(data.alertType)) }}
          </v-chip>
        </v-col> 
        <v-col>
          <v-tooltip bottom color="gray200">
            <template #activator="{ on }">
              <v-chip class="my-1 ml-4" v-on="on">
                <v-icon left class="pa-1">fa-{{ recipients.icon }}</v-icon>
                {{ recipients.text }}
              </v-chip>
            </template>
            <span><p v-for="(team) in teams" :key="team.teamId">{{ team.name }}</p></span>
          </v-tooltip>
        </v-col>
        <v-col class="pa-0">
          <v-chip class="my-1 ml-4" :class="areaLimits.cls">
            <v-icon left class="pa-1">fa-fw fa-{{ areaLimits.icon }}</v-icon>
            {{ areaLimits.text }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text> -->
    <v-card class="ma-2" outlined>
      <v-card-title class="subtitle-1">
        <span class="mr-4"> {{ recipients.text }}</span>
        <v-icon>fal fa-{{ recipients.icon }}</v-icon>
      </v-card-title>
      <v-card-subtitle class="px-0 mt-0">
        <div v-if="teams.length > 0">
          <v-chip v-for="team in teams" :key="team.teamId" class="my-1 ml-4">
            {{ team.name }}
          </v-chip>
        </div>
      </v-card-subtitle>
    </v-card>
    <v-card v-if="hasAreaLimits" class="ma-2" outlined>
      <v-card-title class="subtitle-1">
        <span class="mr-4"> {{ areaLimits.text }}</span>
        <v-icon>fal fa-{{ areaLimits.icon }}</v-icon>
      </v-card-title>
      <!-- <v-card-subtitle class="px-0 mt-0">
        <v-chip class="my-1 ml-4" :class="areaLimits.cls">
          <v-icon left class="pa-1">fa-fw fa-{{ areaLimits.icon }}</v-icon>
          {{ areaLimits.text }}
        </v-chip>
      </v-card-subtitle> -->
    </v-card>
    <v-card v-if="data.attachments && data.attachments.length > 0" class="ma-2" outlined>
      <v-card-title class="subtitle-1">
        <span class="mr-4">{{ $t('attachments') }} ({{ data.attachments.length }})</span>
        <v-icon>fal fa-paperclip</v-icon>
      </v-card-title>
      <v-card-subtitle v-if="data.attachments && data.attachments.length > 0" class="pa-0">
        <attachment-grid :attachments="data.attachments" preview />
      </v-card-subtitle>
    </v-card>
    <v-card v-if="includePosition" class="ma-2" outlined>
      <v-card-title class="subtitle-1">
        <span class="mr-4">{{ $t('includePosition') }}</span>
        <v-icon>fal fa-map-marker-alt</v-icon>
      </v-card-title>
      <v-card-subtitle>{{ $t('includePositionDescription') }}</v-card-subtitle>
    </v-card>
    <v-form ref="form" v-model="formValid" @submit.prevent="start">
      <v-card class="ma-2" outlined>
        <v-card-title class="subtitle-1">
          <span class="mr-4">{{ $t('alertMessage') }}</span>
          <v-icon>fal fa-comment-alt-lines</v-icon>
        </v-card-title>
        <v-card-subtitle
          >{{ $t('scenarioAlertMessageDescription') }}
          <p v-if="isAdmin">{{ $t('scenarioAlertMessageDescriptionAdmin') }}</p>
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="data.alertData.title"
            :rules="formRules.title"
            :label="$t('title')"
            outlined
            :counter="formLengths.title"
          />
          <v-textarea
            v-model="data.alertData.body"
            :rules="formRules.body"
            outlined
            name="input-7-4"
            :label="$t('message')"
            auto-grow
            rows="2"
            :counter="formLengths.body"
          />
        </v-card-text>
      </v-card>
      <v-card-actions class="d-flex justify-end">
        <v-btn v-if="hasCancelButton" class="ma-2" @click="cancel"> {{ $t('cancel') }} </v-btn>
        <v-btn v-if="isAdmin" fab icon class="mr-n2" @click="remove">
          <v-icon color="redPrimary"> fas fa-trash</v-icon>
        </v-btn>
        <v-btn v-if="isAdmin" fab icon @click="edit">
          <v-icon>fal fa-edit</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          class="mr-2"
          elevation="1"
          color="primary"
          type="submit"
          :disabled="!formValid || !data.hasValidTarget"
        >
          Send
          <v-icon right>fa-play</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import AttachmentGrid from '@/components/AttachmentGrid';

const formLengths = {
  title: 50,
  body: 1500,
};

export default {
  name: 'Scenario',
  components: {
    AttachmentGrid,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      require: true,
    },
    allTeams: {
      type: Array,
      required: false,
      default: () => [],
    },
    hasCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: (self) => ({
    alertTypeImage: {
      1: require('@/assets/images/alarm.svg'),
      2: require('@/assets/images/urgent-alert.svg'),
    },
    formValid: false,
    formLengths,
    formRules: {
      title: [
        (title) =>
          title?.length >= 3 ||
          self.$t('mustBeMinimum', null, {
            value: self.$t('title'),
            characters: 3,
          }),
        (title) =>
          title?.length <= formLengths.title ||
          self.$t('mustBeMaximum', null, {
            value: self.$t('title'),
            characters: formLengths.title,
          }),
      ],
      body: [
        (body) =>
          body?.length >= 3 ||
          self.$t('mustBeMinimum', null, {
            value: self.$t('message'),
            characters: 3,
          }),
        (body) =>
          body?.length <= formLengths.body ||
          self.$t('mustBeMaximum', null, {
            value: self.$t('message'),
            characters: formLengths.body,
          }),
      ],
    },
  }),

  computed: {
    emblem: function () {
      let filename = '';
      filename =
        {
          2: 'urgent-alert-darkYellow',
          1: 'alarm-white',
        }[this.data.alertType] || 'alarm-white';
      return require(`@/assets/images/${filename}.svg`);
    },
    alertTypeClass: function () {
      return (
        {
          2: 'alert',
          1: 'alarm',
        }[this.data.alertType] || 'alert'
      );
    },
    target() {
      return this.data.alertData?.target ?? {};
    },

    company() {
      return this.target.company ?? false;
    },

    teams() {
      if (this.target.teams && this.target.teams.length > 0 && this.allTeams.length > 0) {
        let toRemove = new Array();
        this.target.teams.forEach((team) => {
          let exists = this.allTeams.some((x) => x.id === team);
          if (!exists) {
            toRemove.push(team);
          }
        });
        toRemove.forEach((team) => {
          this.target.teams.splice(this.target.teams.indexOf(team), 1);
        });

        return this.target.teams.map((team) => this.allTeams.find((t) => t.id === team));
      } else return [];
    },

    users() {
      return this.target.users ?? [];
    },

    devices() {
      return this.target.devices ?? [];
    },

    locations() {
      return this.target.locations ?? [];
    },

    adHocLocation() {
      return this.target.adHocLocation ?? '';
    },
    includePosition() {
      return this.data.includePosition ?? false;
    },

    recipients() {
      let text;
      let icon;

      if (this.company) {
        text = this.$t('company');
        icon = 'building';
      } else if (this.teams.length > 0) {
        text = `${this.teams.length} ${(this.teams.length === 1
          ? this.$t('team')
          : this.$t('teams')
        ).toLowerCase()}`;
        icon = 'users';
      } else if (this.users.length > 0) {
        text = `${this.users.length} ${(this.users.length === 1
          ? this.$t('member')
          : this.$t('members')
        ).toLowerCase()}`;
        icon = 'user';
      } else if (this.devices.length > 0) {
        text = `${this.devices.length} ${(this.devices.length === 1
          ? this.$t('device')
          : this.$t('devices')
        ).toLowerCase()}`;
        icon = 'user';
      } else {
        text = 'Unknown target';
        icon = 'question';
      }

      return {
        text,
        icon,
      };
    },

    areaLimits() {
      let text;
      let icon;
      let cls = '';

      if (this.locations.length > 0) {
        text = `${this.locations.length} ${(this.locations.length === 1
          ? this.$t('locationPredefined')
          : this.$t('locationsPredefined')
        ).toLowerCase()}`;
        icon = 'map-pin';
      } else if (this.adHocLocation.length > 0) {
        text = this.$t('adHocLocation');
        icon = 'draw-polygon';
      } else {
        text = 'No location';
        icon = 'map-pin';
        cls = 'hidden';
      }

      return {
        text,
        icon,
        cls,
      };
    },
    hasAreaLimits() {
      return this.locations.length > 0 || this.adHocLocation.length > 0;
    },
  },

  methods: {
    alertTypeDisplayName(alertType) {
      return (
        {
          1: 'alarm',
          2: 'urgentAlert',
        }[alertType] ?? 'urgentAlert'
      );
    },

    edit() {
      this.$emit('edit', this.data);
    },

    start() {
      this.$emit('start', this.data);
    },

    remove() {
      this.$emit('remove', this.data);
    },
    cancel() {
      this.$emit('cancel', this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}
.alertTitleBox {
  display: inline-block;
  width: 100%;
  margin: 0;

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);

  &.alert {
    background: linear-gradient(180deg, #eec437 0%, #efb145 100%);
    color: #553e07;
  }
  &.alarm {
    background: linear-gradient(180deg, #da2121 0%, #a91a10 100%);
    color: white;
  }
}
</style>
