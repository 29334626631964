import Vue from 'vue';
import { translate, getLanguage } from '@/util/i18n';
const lang = getLanguage();

const plugin = {
  install: () => {
    Vue.translate = translate;
    Vue.prototype.$t = translate;
    Vue.prototype.$lang = lang;
  },
};

Vue.use(plugin);
