import vuetify from '@/plugins/vuetify';

let currentDialog = null;

export const closeCurrentDialog = () => {
  if (currentDialog) {
    currentDialog.close();
  }
};

const dialog = (config) => {
  if (!['info', 'warning', 'error'].find((type) => config.props.type === type)) {
    config.props.type = 'info';
  }

  const instance = new config.component({
    vuetify,
    propsData: config.props,
  });
  instance.$mount();

  const closeAfterAnimation = () => {
    setTimeout(() => {
      instance.$destroy();
      instance.$el.remove();
    }, 300);
  };

  instance.$on('cancel', () => {
    closeAfterAnimation();
  });

  instance.$on('confirm', () => {
    closeAfterAnimation();
  });

  const open = () =>
    new Promise((resolve) => {
      instance.doOpen();

      instance.$on('cancel', () => {
        resolve(false);
      });

      instance.$on('confirm', () => {
        resolve(true);
      });

      document.querySelector('#app').appendChild(instance.$el);
    });

  const close = () => {
    instance.doCancel();
  };

  const dialogInstance = {
    open,
    close,
  };

  closeCurrentDialog();
  currentDialog = dialogInstance;
  return dialogInstance;
};

export default dialog;
