import Vue from 'vue';
import formatByteSize from '@/util/formatByteSize';

const plugin = {
  install: () => {
    Vue.formatByteSize = formatByteSize;
    Vue.prototype.$formatByteSize = formatByteSize;
  },
};

Vue.use(plugin);
