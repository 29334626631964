var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('LMap',{ref:"myMap",staticClass:"map",attrs:{"zoom":_vm.zoom,"center":_vm.calculatedCenter,"options":_vm.mapOptions},on:{"ready":_vm.onMapReady}},[_c('l-control-fullscreen',{attrs:{"position":"topright","options":{ title: { false: 'Fullscreen', true: 'Regular' } }}}),_c('l-control',{attrs:{"position":"bottomright"}},[_c('div',{staticClass:"map-layer-menu",attrs:{"id":"mapLayerMenu"}}),_c('v-menu',{attrs:{"top":"","left":"","offset-y":"","attach":"#mapLayerMenu"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"gray200","light":"","aria-label":"Select maplayer"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fal fa-layer-group")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.enabledMapTypes),function(enabledMapType,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.setSelectedMapType(enabledMapType.value)}}},[_c('v-list-item-icon',[(enabledMapType.value === _vm.selectedMapType)?_c('v-icon',[_vm._v("fal fa-check-square")]):_c('v-icon',[_vm._v("fal fa-square")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(enabledMapType.name)))])],1)],1)}),1)],1)],1),_c('l-tile-layer',{attrs:{"url":_vm.selectedMapTypeInfo.url,"attribution":_vm.attribution,"subdomains":_vm.selectedMapTypeInfo.subdomains}}),_vm._l((_vm.markers),function(l){return _c('l-marker',{key:l.id,attrs:{"lat-lng":{ lat: l.lat, lng: l.lng },"icon":_vm.icon}},[_c('l-tooltip',{attrs:{"content":l.name,"options":{
          permanent: true,
          interactive: false,
          direction: 'top',
          offset: [0, -10],
          opacity: 0.95,
        }}})],1)}),(_vm.polygon.length > 0)?_c('l-polygon',{attrs:{"fill-color":"#000000","fill-opacity":0.3,"stroke":true,"color":"red","weight":1,"lat-lngs":_vm.polygon}},[_c('l-tooltip',{attrs:{"content":_vm.translatedAreaName,"options":{
          permanent: true,
          interactive: false,
          direction: 'top',
          offset: [0, -10],
          opacity: 0.95,
        }}})],1):_vm._e(),(_vm.circle)?_c('l-circle',{ref:"myCircle",attrs:{"lat-lng":_vm.circle.center,"radius":_vm.circle.radius,"color":"red"}},[_c('l-tooltip',{attrs:{"content":_vm.translatedAreaName,"options":{
          permanent: true,
          interactive: false,
          direction: 'top',
          offset: [0, -10],
          opacity: 0.95,
        }}})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }