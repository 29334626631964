import Vue from 'vue';
import dialog from '@/components/dialog/dialog';
import ImageDialog from '@/components/dialog/ImageDialog.vue';

const imageDialog = (src, alt) =>
  dialog({
    props: {
      src,
      alt,
    },
    component: Vue.extend(ImageDialog),
  });

export default imageDialog;
