<template>
  <v-container>
    <div class="d-flex clickable" @click="viewDetails">
      <alert-emblem
        :alert-type="alert.type"
        :is-closed="alert.status === 'completed' ? true : false"
        class="mr-3"
      />
      <div class="alert-info d-flex flex-column ml-3">
        <h4>
          {{ alert.title }}
          <v-tooltip v-if="alert.isLocationRestricted" bottom color="rgba(0,0,0,0.9)">
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" right class="ml-4 mb-1" size="20" v-on="on"
                >fal fa-map
              </v-icon>
            </template>
            <span>{{ $t('withinArea') }}</span>
          </v-tooltip>
          <v-tooltip v-if="alert.containsAuthorLocation" bottom color="rgba(0,0,0,0.9)">
            <template #activator="{ on, attrs }">
              <v-icon right class="ml-4 mb-1" size="20" v-bind="attrs" v-on="on">
                fal fa-map-marker-alt
              </v-icon>
            </template>
            <span>{{ $t('sendersPosition') }}</span>
          </v-tooltip>
        </h4>

        <p v-if="singleLine" class="single-line mt-3">{{ alert.message }}</p>
        <p v-else class="triple-line mt-3">{{ alert.message }}</p>
        <p class="mb-0 caption mb-3">{{ formatDate(alert.date) }}</p>
      </div>
      <div class="hidden-sm-and-down ml-auto">
        <div v-if="displayUser" class="d-flex flex-column justify-center">
          <profile-photo
            :url="alert.user?.profilePhoto"
            :alt="alert.user?.name"
            :size="'90px'"
            :light-border="true"
            :clickable="false"
          />
          <span class="align-self-center">{{ alert.user?.name }}</span>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment';
import AlertEmblem from './AlertEmblem.vue';
import ProfilePhoto from '@/components/ProfilePhoto.vue';

export default {
  name: 'AlertCard',

  components: {
    AlertEmblem,
    ProfilePhoto,
  },

  props: {
    alert: {
      type: Object,
      required: true,
    },
    singleLine: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    displayUser: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    formatDate(value) {
      return moment(value).format('DD.MM.YYYY HH:mm');
    },
    viewDetails() {
      this.$emit('viewDetails', this.alert.id);
    },
  },
};
</script>

<style scoped lang="scss">
.alert-info {
  overflow: hidden;
}
.single-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.triple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
}
</style>
