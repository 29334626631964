<template>
  <v-card class="card flex pa-4" elevation="3" outlined>
    <v-row justify="center">
      <v-card-title align-center>
        {{ customer.name }}
      </v-card-title>
    </v-row>
    <v-list class="ml-5">
      <v-list-item>
        <v-list-item-title>{{ $t('currentPlan') }}</v-list-item-title>
        <v-list-item-subtitle>
          <span class="counter">{{ ucFirst(customer.plan) }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>{{ $t('users') }}</v-list-item-title>
        <v-list-item-subtitle>
          <ICountUp
            class="counter no-wrap"
            :delay="countupDelay"
            :end-val="usersCount"
            :options="countupOptions"
          />
          <span class="counter">/{{ customer.maxUsers }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>{{ $t('alertsActive') }}</v-list-item-title>
        <v-list-item-subtitle>
          <ICountUp
            class="counter no-wrap"
            :delay="countupDelay"
            :end-val="activeAlertsCount"
            :options="countupOptions"
          />
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import ICountUp from 'vue-countup-v2';
import { ucFirst } from '@/lib/util/capitalize';

export default {
  name: 'DashSummary',
  components: {
    ICountUp,
  },
  props: {
    usersCount: {
      type: Number,
      required: true,
      default: () => 0,
    },
    activeAlertsCount: {
      type: Number,
      required: true,
      default: () => 0,
    },
    customer: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: () => ({
    countupDelay: 0,
    countupOptions: {
      useEasing: true,
      useGrouping: true,
      separator: ' ',
      decimal: '.',
      prefix: '',
      suffix: '',
    },
  }),
  methods: {
    ucFirst,
  },
};
</script>

<style lang="scss" scoped>
.counter {
  font-size: 1.4em;
  text-align: center;
  font-weight: 600;
}
</style>
