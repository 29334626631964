import Vue from 'vue';
import dialog from '@/components/dialog/dialog';
import TextDialog from '@/components/dialog/TextDialog.vue';

const confirmDialog = (title, content, cancelText, confirmText, type, verification) =>
  dialog({
    props: {
      title,
      content,
      cancelText,
      confirmText,
      type,
      confirm: true,
      verification,
    },
    component: Vue.extend(TextDialog),
  });

export default confirmDialog;
