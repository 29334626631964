<template>
  <div>
    <LMap
      ref="myMap"
      class="map"
      :zoom="zoom"
      :center="calculatedCenter"
      :options="mapOptions"
      @ready="onMapReady"
    >
      <l-control-fullscreen
        position="topright"
        :options="{ title: { false: 'Fullscreen', true: 'Regular' } }"
      />
      <l-control position="bottomright">
        <div id="mapLayerMenu" class="map-layer-menu" />
        <v-menu top left offset-y attach="#mapLayerMenu">
          <template #activator="{ on, attrs }">
            <v-btn color="gray200" light v-bind="attrs" aria-label="Select maplayer" v-on="on">
              <v-icon>fal fa-layer-group</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(enabledMapType, index) in enabledMapTypes"
              :key="index"
              @click="setSelectedMapType(enabledMapType.value)"
            >
              <v-list-item-icon>
                <v-icon v-if="enabledMapType.value === selectedMapType">fal fa-check-square</v-icon>
                <v-icon v-else>fal fa-square</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(enabledMapType.name) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </l-control>
      <l-tile-layer
        :url="selectedMapTypeInfo.url"
        :attribution="attribution"
        :subdomains="selectedMapTypeInfo.subdomains"
      />
      <l-marker v-for="l in markers" :key="l.id" :lat-lng="{ lat: l.lat, lng: l.lng }" :icon="icon">
        <l-tooltip
          :content="l.name"
          :options="{
            permanent: true,
            interactive: false,
            direction: 'top',
            offset: [0, -10],
            opacity: 0.95,
          }"
        />
      </l-marker>
      <l-polygon
        v-if="polygon.length > 0"
        fill-color="#000000"
        :fill-opacity="0.3"
        :stroke="true"
        color="red"
        :weight="1"
        :lat-lngs="polygon"
      >
        <l-tooltip
          :content="translatedAreaName"
          :options="{
            permanent: true,
            interactive: false,
            direction: 'top',
            offset: [0, -10],
            opacity: 0.95,
          }"
        />
      </l-polygon>
      <l-circle
        v-if="circle"
        ref="myCircle"
        :lat-lng="circle.center"
        :radius="circle.radius"
        color="red"
      >
        <l-tooltip
          :content="translatedAreaName"
          :options="{
            permanent: true,
            interactive: false,
            direction: 'top',
            offset: [0, -10],
            opacity: 0.95,
          }"
        />
      </l-circle>
    </LMap>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPolygon, LCircle, LControl, LTooltip } from 'vue2-leaflet';
import LControlFullscreen from 'vue2-leaflet-fullscreen';
import { icon } from 'leaflet';

const googleSubdomains = ['mt0', 'mt1', 'mt2', 'mt3'];
const googleMapTileUrl = (lyrs) =>
  `https://{s}.google.com/vt/lyrs=${lyrs}&x={x}&y={y}&z={z}&language=en`;

// See https://stackoverflow.com/questions/33343881/leaflet-in-google-maps#answer-58811749
const allMapTypes = {
  streets: {
    url: googleMapTileUrl('m'),
    subdomains: googleSubdomains,
    name: 'map.road',
  },
  satellite: {
    url: googleMapTileUrl('s'),
    subdomains: googleSubdomains,
    name: 'map.satellite',
  },
  hybrid: {
    url: googleMapTileUrl('s,h'),
    subdomains: googleSubdomains,
    name: 'map.hybrid',
  },
  terrain: {
    url: googleMapTileUrl('p'),
    subdomains: googleSubdomains,
    name: 'map.terrain',
  },
  //TODO: Enable for norwegian customers?
  // kartverket: {
  //   url: 'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4graatone&format=image/jpeg&zoom={z}&x={x}&y={y}',
  //   subdomains: googleSubdomains,
  //   name: 'map.grayscale',
  // },
};

export default {
  name: 'LeafletMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LPolygon,
    LControlFullscreen,
    LTooltip,
    LCircle,
  },
  props: {
    markers: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
    polygon: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
    circle: {
      type: Object,
      default: null,
      required: false,
    },
    areaName: {
      type: String,
      default: null,
      required: false,
    },
    mapTypes: {
      type: Array,
      default: () => Object.keys(allMapTypes),
    },
  },
  data() {
    return {
      zoom: 6,
      calculatedCenter: [59.911491, 10.757933],
      bounds: null,
      mapOptions: {
        zoomSnap: 1,
        minZoom: 3,
        maxBounds: [
          [-90, -180],
          [90, 180],
        ],
        zoomControl: false,
      },
      attribution:
        '&copy; <a href="https://about.google/brand-resource-center/products-and-services/geo-guidelines/">Google Maps</a>',
      selectedMapType: 'streets',
      icon: icon({
        iconUrl: '/static/images/position.svg',
        iconSize: [20, 20],
        iconAnchor: [10, 10],
      }),
    };
  },
  computed: {
    selectedMapTypeInfo() {
      return allMapTypes[this.selectedMapType];
    },

    enabledMapTypes() {
      const enabled = [];
      Object.keys(allMapTypes).forEach((mt) => {
        if (this.mapTypes.includes(mt)) {
          enabled.push({
            ...allMapTypes[mt],
            value: mt,
          });
        }
      });
      return enabled;
    },
    translatedAreaName() {
      if (this.areaName === '' || this.areaName === null) return this.$t('adHocLocation');
      else return this.areaName;
    },
  },
  created() {
    let storedMapType = localStorage.getItem('TeamAlertMapType');
    if (storedMapType) {
      this.selectedMapType = storedMapType;
    }
  },
  methods: {
    onMapReady() {
      this.map = this.$refs.myMap.mapObject;
      this.redraw();
      this.$emit('ready');
    },
    calculateCenter(map) {
      if (this.circle !== null) {
        let mapCircle = this.$refs.myCircle.mapObject;
        map.fitBounds(mapCircle.getBounds(), { padding: [50, 50] });
        //TODO: Find a clever way to zoom to circle
        return;
      }
      if (this.polygon.length > 0) {
        map.fitBounds(this.polygon, { padding: [50, 50] });
        return;
      }
      if (this.markers.length === 0) {
        return;
      }

      let bounds = this.markers.map((m) => [m.lat, m.lng]);
      map.fitBounds(bounds, { padding: [150, 150] });
      return;
    },
    redraw() {
      this.map.invalidateSize(false);
      this.calculateCenter(this.map);
    },
    setSelectedMapType(mapType) {
      localStorage.setItem('TeamAlertMapType', mapType);
      this.selectedMapType = mapType;
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  z-index: 1;
}
.map-layer-menu {
  width: 200px;
  right: -136px;
  position: absolute;
  bottom: 45px;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
</style>
