import { cr } from './dom';

/**
 * Creates an image
 *
 * @param {string} src the image source path
 * @param {string} className the class name of the image element
 * @param {string} alt an alternative text for the image, for screen readers
 */
const img = (src, className, alt) =>
  cr('img', className, null, {
    src,
    alt,
  });

export default img;
