<template>
  <div class="wrapper d-flex align-center justify-center black" :style="{ width, height }">
    <v-img :src="thumbnail" />
    <v-icon color="white" class="play-icon" large>fa-play-circle</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: null,
    },

    width: {
      type: String,
      default: null,
    },

    height: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    thumbnail: null,
  }),

  mounted() {
    this.loadThumbnail();
  },

  methods: {
    loadThumbnail() {
      if (!this.src) return;

      const video = document.createElement('video');

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      video.addEventListener(
        'timeupdate',
        () => {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          video.pause();

          const dataURI = canvas.toDataURL('image/jpeg');
          this.thumbnail = dataURI;
        },
        false,
      );

      video.preload = 'metadata';
      video.muted = true;
      video.playsInline = true;
      video.setAttribute('crossOrigin', 'anonymous');
      video.src = this.src;
      video.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  text-align: center;

  .play-icon {
    opacity: 0.7;
    position: absolute;
  }
}
</style>
