import { cr } from './dom';

/**
 * Creates an HTML <i> element with a Google Material Design (GMD) icon
 * @param  {string} type            the GMD icon id, e.g. 'menu'
 * @param  {string} className  an extra className (optional)
 * @return {HTMLDomElement}         an HTML <i>
 */
const i = (type, className, attrs) => {
  className = 'fas fa-' + type + (className ? ' ' + className : '');

  const iElement = cr('i', className, null, attrs);
  return iElement;
};

export default i;
