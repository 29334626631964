import Vue from 'vue';
import VueRouter from 'vue-router';
import Dash from '@/views/dash/Dash';
import auth from '@/util/auth';
import { translate as t } from '@/util/i18n';
import { closeCurrentDialog } from './components/dialog/dialog';
import appSettings from './appSettings';
import getUserProfile from '@/util/getUserProfile';
import { ADMIN, SUPERADMIN, USER } from './consts/roles';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dash,
    meta: { titleTranslation: t('dashboard') },
  },
  {
    path: '/alerts',
    alias: ['/alerts/active', '/alerts/closed'],
    name: 'alerts',
    component: () => import(/* webpackChunkName: "alerts" */ '@/views/alertLogs/Alerts.vue'),
    meta: { titleTranslation: t('alertLogPageTitle') },
  },
  {
    path: '/alerts/:id',
    name: 'alert',
    component: () => import(/* webpackChunkName: "alert" */ '@/views/alertDetails/Alert.vue'),
    meta: { titleTranslation: t('alert') },
  },
  {
    path: '/alerts/delivery/:id',
    name: 'alertDelivery',
    component: () =>
      import(/* webpackChunkName: "alert" */ '@/views/alertDetails/DeliveryDetails.vue'),
    meta: { titleTranslation: t('deliveryreports') },
  },
  {
    path: '/automation/scenarios/:id?',
    name: 'scenarios',
    component: () => import(/* webpackChunkName: "scenarios" */ '@/views/scenarios/Scenarios.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('scenarios') },
  },
  {
    path: '/automation/iotDevices',
    name: 'iotDevices',
    component: () =>
      import(/* webpackChunkName: "iotDevices" */ '@/views/iotDevices/IotDevices.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('iotDevices') },
  },
  {
    path: '/company/azureAd',
    name: 'azureAd',
    component: () =>
      import(/* webpackChunkName: "azureAdSettings" */ '@/views/company/AzureAdSettings.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('azureAd') },
  },
  {
    path: '/company/locations',
    name: 'locations',
    component: () => import(/* webpackChunkName: "locations" */ '@/views/company/Locations.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('locations') },
  },
  {
    path: '/company/members',
    name: 'members',
    component: () =>
      import(/* webpackChunkName: "members" */ '@/views/company/company-members/Members.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('company') },
  },
  {
    path: '/company/settings',
    name: 'settings',
    component: () =>
      import(/* webpackChunkName: "settings" */ '@/views/company/settings/Settings.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('settings') },
  },
  {
    path: '/company/teams',
    name: 'teams',
    component: () => import(/* webpackChunkName: "teams" */ '@/views/company/teams/Teams.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('teams') },
  },
  {
    path: '/company/teams/:id',
    name: 'team',
    component: () => import(/* webpackChunkName: "team" */ '@/views/company/teams/Team.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('team') },
  },
  {
    path: '/company/voice',
    name: 'voice',
    component: () => import(/* webpackChunkName: "voice" */ '@/views/company/VoiceSettings.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('voice') },
  },
  {
    path: '/confirmAdmin',
    name: 'confirmCompany',
    component: () =>
      import(/* webpackChunkName: "confirmEmail" */ '@/views/loggedOut/ConfirmEmail.vue'),
    meta: { allowAnonymous: true, disableNav: true, titleTranslation: t('confirmCompany') },
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () =>
      import(/* webpackChunkName: "setNewPassword" */ '@/views/loggedOut/SetNewPassword.vue'),
    meta: { allowAnonymous: true, disableNav: true, titleTranslation: t('forgotPassword') },
  },
  {
    path: '/setNewPassword',
    name: 'setNewPassword',
    component: () =>
      import(/* webpackChunkName: "setNewPassword" */ '@/views/loggedOut/SetNewPassword.vue'),
    meta: { allowAnonymous: true, disableNav: true, titleTranslation: t('setNewPassword') },
  },
  {
    path: '/loggedOut',
    name: 'loggedOut',
    component: () => import(/* webpackChunkName: "loggedOut" */ '@/views/loggedOut/LoggedOut.vue'),
    meta: { allowAnonymous: true, disableNav: true, titleTranslation: t('loggedOut') },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/loggedOut/Login.vue'),
    meta: { allowAnonymous: true, disableNav: true, titleTranslation: t('login') },
  },
  {
    path: '/missions/:id?',
    name: 'missions',
    component: () => import(/* webpackChunkName: "missions" */ '@/views/missions/Missions.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('missions') },
  },
  {
    path: '/newAlert',
    name: 'newAlert',
    component: () => import(/* webpackChunkName: "newAlert" */ '@/views/newAlert/NewAlert.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('newAlertPageTitle') },
  },
  {
    path: '/newCompany',
    name: 'newCompany',
    component: () => import(/* webpackChunkName: "newCompany" */ '@/views/NewCompany.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('newCompany') },
  },
  {
    path: '/newPositioning',
    name: 'newPositioning',
    component: () =>
      import(/* webpackChunkName: "newPositioning" */ '@/views/newPositioning/NewPositioning.vue'),
    meta: { minRequiredRole: ADMIN, titleTranslation: t('newPositioningPageTitle') },
  },
  {
    path: '/positionings',
    name: 'positionings',
    component: () =>
      import(/* webpackChunkName: "positionings" */ '@/views/positioningLogs/Positionings.vue'),
    meta: { titleTranslation: t('positionings') },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Profile.vue'),
    meta: { titleTranslation: t('profile') },
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () =>
      import(/* webpackChunkName: "resetPassword" */ '@/views/loggedOut/ResetPassword.vue'),
    meta: { allowAnonymous: true, disableNav: true, titleTranslation: t('resetPassword') },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/loggedOut/Signup.vue'),
    meta: { allowAnonymous: true, disableNav: true, titleTranslation: t('registerPageTitle') },
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "notFound" */ '@/views/NotFound404.vue'),
    meta: { allowAnonymous: true, disableNav: true, titleTranslation: '404 Not Found' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: appSettings.BASE_URL,
  routes,
});

const authorized = (role, minRequiredRole) => {
  const roleOrder = [USER, ADMIN, SUPERADMIN];

  const roleIndex = roleOrder.indexOf(role);
  const minRequiredRoleIndex = roleOrder.indexOf(minRequiredRole);

  return roleIndex >= minRequiredRoleIndex;
};

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.titleTranslation + ' | ' + 'TeamAlert';
  closeCurrentDialog();
  const allowAnonymous = to.meta.allowAnonymous;

  if (allowAnonymous) return next();

  if (!auth.loggedIn()) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  }
  const { minRequiredRole } = to.meta;

  if (minRequiredRole) {
    const profile = await getUserProfile();
    if (!authorized(profile.user.role, minRequiredRole)) {
      // role not authorised so redirect to home page
      document.title = 'TeamAlert';
      return router.push('/').catch(() => {});
    }
  }
  next();
});

export default router;
