<template>
  <div class="emblem" :class="[alertTypeClass, { closed: isClosed }]">
    <img class="alert-image" contain :src="emblem" />
  </div>
</template>

<script>
export default {
  name: 'AlertEmblem',
  props: {
    alertType: {
      type: String,
      required: false,
      default: () => 'urgent-alert',
    },
    isClosed: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    emblem: function () {
      let filename = '';
      if (!this.isClosed) {
        filename =
          {
            urgentAlert: 'urgent-alert-darkYellow',
            sos: 'sos-white',
            alarm: 'alarm-white',
            missionAlarm: 'alarm-white',
          }[this.alertType] || 'urgent-alert-darkYellow';
      } else {
        filename =
          {
            urgentAlert: 'urgent-alert-gray',
            sos: 'sos-gray',
            alarm: 'alarm-gray',
            missionAlarm: 'alarm-gray',
          }[this.alertType] || 'urgent-alert-gray';
      }
      return require(`@/assets/images/${filename}.svg`);
    },
    alertTypeClass: function () {
      return (
        {
          urgentAlert: 'alert' || 'urgent-alert',
          sos: 'alarm',
          alarm: 'alarm',
          missionAlarm: 'alarm',
        }[this.alertType] || 'alert'
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/style/scss/settings/colors';

.emblem {
  padding: 16px;
  max-width: 100px;
  max-height: 100px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  border-radius: 8px !important;
  &.alert {
    background: linear-gradient(180deg, #ffce12 0%, #ffb119 100%);
    p {
      color: #553e07 !important;
    }
    button {
      color: #553e07 !important;
    }
  }
  &.alarm {
    background: linear-gradient(180deg, #cb1919 0%, #830808 100%);
    p {
      color: white;
    }
    button {
      color: white !important;
    }
  }
  &.closed {
    background: linear-gradient(180deg, #c1c1c1 0%, #b7b7b7 100%);
    p {
      color: $colorGray900 !important;
    }
    button {
      color: $colorGray900 !important;
    }
  }
}
.alert-image {
  width: 60px;
  max-width: 60px;
  height: 60px;
  max-height: 60px;
  margin: 0px 4px;
  padding: 4px 0px 0px 0px;
}
</style>
