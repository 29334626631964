import Vue from 'vue';
import signalRConnection from '@/util/signalRConnection';

const plugin = {
  install: () => {
    Vue.signalR = signalRConnection;
    Vue.prototype.$signalR = signalRConnection;
  },
};

Vue.use(plugin);
