import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import * as colors from '@/lib/ui/colors';
import VuetifyToast from 'vuetify-toast-snackbar';
import '@fortawesome/fontawesome-pro/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        ...Object.assign({}, colors, {
          gray100transparent20: null,
          gray900transparent50: null,
        }),
        primary: colors.blueDark,
        secondary: colors.gray200,
        success: colors.bluePrimary,
        info: colors.blueDark,
        warning: colors.yellowDark,
        error: colors.redDark,
      },
      dark: {
        ...Object.assign({}, colors, {
          gray100transparent20: null,
          gray900transparent50: null,
        }),
        primary: colors.blueDark,
        secondary: colors.gray200,
        success: colors.bluePrimary,
        info: colors.blueDark,
        warning: colors.yellowDark,
        error: colors.redDark,
      },
    },
  },
});
Vue.use(VuetifyToast, {
  x: 'right', // default
  y: 'top', // default
  color: 'error', // default
  icon: 'fa-info',
  iconColor: '', // default
  classes: ['body-2'],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: false, // default
  closeText: '', // default
  closeIcon: 'close', // default
  closeColor: '', // default
  slot: [], // default
  shorts: {
    custom: {
      color: 'purple',
    },
  },
  property: '$toast', // default
});
