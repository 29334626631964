<template>
  <v-select
    :items="companies"
    :value="currentCompany"
    item-text="name"
    item-value="id"
    hide-details
    solo-inverted
    class="company-selector"
    flat
    light
    @change="changeCompany"
  >
    <template #selection="{ item }">
      <h1 class="title font-weight-thin ma-0">{{ item.name }}</h1>
    </template>

    <template #item="{ item, on }">
      <v-list-item :disabled="item.id === currentCompany.id" v-on="on">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template #append-item>
      <v-list-item to="/newCompany">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('createNewCompany') }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon>fa-plus</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import auth from '@/util/auth';
export default {
  name: 'CompanySwitcher',

  props: {
    companies: {
      type: Array,
      required: true,
    },

    currentCompany: {
      type: Object,
      required: false,
      default: null,
    },
  },

  methods: {
    changeCompany(selectedCompanyId) {
      auth.setCustomerId(selectedCompanyId);
      this.$router.replace('/');
      this.$router.go();
    },
  },
};
</script>

<style lang="scss" scoped>
.company-selector ::v-deep .v-input__control > .v-input__slot {
  background: transparent !important;

  &:hover,
  &:focus,
  &:hover:focus {
    background: rgba(255, 255, 255, 0.16) !important;
  }
}
</style>
