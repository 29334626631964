import is from '../lib/util/is';
import { ap, cr } from '../lib/ui/dom';
import htmlMessageDialog from '@/components/dialog/htmlMessageDialog';
import { translate as t } from '../util/i18n';

const errorsToList = (res) => {
  let errors;

  if (is.array(res)) {
    errors = res;
  } else if (res instanceof TypeError) {
    errors = [res.toString()];
  } else {
    errors = res?.response?.errors?.map((error) => error.message) ?? [JSON.stringify(res)];
  }

  return ap(
    cr('ol'),
    errors.map((error) => cr('li', null, error)),
  );
};

const errorMessage = (errorTitle, errorMessage, res) =>
  htmlMessageDialog(
    errorTitle,
    ap(cr(), cr('span', null, errorMessage), errorsToList(res)),
    t('ok'),
    'error',
  ).open();

export default errorMessage;
