<template>
  <v-dialog v-model="show" max-width="400px" eager @click:outside="doCancel" @keydown="onKeydown">
    <v-card class="ta-dialog">
      <v-card-title :class="type" class="white--text">
        <v-icon color="white">{{ icon }}</v-icon>
        <span class="ml-4">{{ title }}</span>
      </v-card-title>
      <v-card-text class="pt-5">
        <div v-if="!contentIsHtml" v-html="content" />
        <div v-else ref="htmlContent" />
        <div v-if="isVerificationEnabled" class="mt-6">
          <v-text-field v-model="verificationInputValue" :label="verification.text" />
        </div>
      </v-card-text>
      <v-card-actions v-if="confirm">
        <v-spacer />
        <v-btn @click="doCancel">{{ cancelText }}</v-btn>
        <v-btn :disabled="isConfirmDisabled" :color="type" @click="doConfirm">{{
          confirmText
        }}</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer />
        <v-btn :color="type" @click="doCancel">{{ confirmText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TextDialog',

  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: [String, HTMLElement],
      required: true,
    },
    cancelText: {
      type: String,
      required: true,
    },
    confirmText: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    confirm: {
      type: Boolean,
      required: true,
    },
    verification: {
      default: undefined,
      type: Object,
      required: false,
      validator: (prop) =>
        prop &&
        typeof prop === 'object' &&
        typeof prop.text === 'string' &&
        prop.text.length > 0 &&
        typeof prop.value === 'string' &&
        prop.value.length > 0,
    },
  },

  data: () => ({
    show: null,
    verificationInputValue: null,
  }),

  computed: {
    icon() {
      return {
        info: 'fa-info-circle',
        warning: 'fa-exclamation-triangle',
        error: 'fa-exclamation-circle',
      }[this.type];
    },

    contentIsHtml() {
      return this.content instanceof HTMLElement;
    },

    isVerificationEnabled() {
      return (
        this.verification &&
        typeof this.verification === 'object' &&
        typeof this.verification.value === 'string' &&
        this.verification.value.length > 0 &&
        typeof this.verification.text === 'string' &&
        this.verification.text.length > 0
      );
    },

    isConfirmDisabled() {
      return this.isVerificationEnabled && this.verification.value !== this.verificationInputValue;
    },
  },

  mounted() {
    if (this.$refs.htmlContent) {
      this.$refs.htmlContent.append(this.content);
    }
  },

  methods: {
    doOpen() {
      this.show = true;
      this.$emit('show');
    },

    doCancel() {
      this.show = false;
      this.$emit('cancel');
    },

    doConfirm() {
      this.show = false;
      this.$emit('confirm');
    },

    onKeydown(e) {
      if (e.key === 'Escape') {
        this.doCancel();
      }
    },
  },
};
</script>
