import asyncElement from '../../lib/ui/asyncElement';
import img from '../../lib/ui/img';
import { on, ac } from '../../lib/ui/dom';
import imageDialog from '@/components/dialog/imageDialog';

export const emptyStateImage = '/static/images/empty-states/profile-picture.svg';

export const profilePhotoSrc = (userName, src) => {
  let error = false;
  const image = img(src, null, userName);
  const asyncImage = asyncElement(image, 'profile-photo');

  on(image, 'load', () => {
    if (!error) {
      ac(asyncImage, 'clickable');
      on(asyncImage, 'click', () => {
        imageDialog(image.src, image.alt).open();
      });
    }
  });

  on(image, 'error', () => {
    error = true;
    image.src = emptyStateImage;
  });

  return asyncImage;
};
