<template>
  <v-container v-if="loaded" fluid>
    <v-row class="ma-2">
      <v-col
        cols="12"
        md="6"
        class="pa-3 d-flex flex-column"
        :class="{ 'pulse animated': alertsCardAnimated }"
      >
        <DashAlerts :latest-alerts="latestAlerts" :active-alerts-count="activeAlertsCount" />
      </v-col>
      <template v-if="isAdminProfile">
        <v-col v-if="hasDevices && !showReminder" cols="12" md="6" class="pa-3 d-flex flex-column">
          <DashSummary
            :users-count="usersCount"
            :active-alerts-count="activeAlertsCount"
            :customer="profile.customer"
          />
        </v-col>
        <v-col v-if="!hasDevices || showReminder" cols="12" md="6" class="pa-3 d-flex flex-column">
          <DashReminders
            :has-devices="hasDevices"
            :show-reminder="showReminder"
            :users-without-devices="usersWithoutDevices"
          />
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" md="6" class="pa-3 d-flex flex-column">
          <DashSummary
            :users-count="usersCount"
            :active-alerts-count="activeAlertsCount"
            :customer="profile.customer"
          />
        </v-col>
      </template>
      <v-col
        v-if="hasMissionFeature && isAdminProfile"
        cols="12"
        md="6"
        class="pa-3 d-flex flex-column"
        :class="{ 'pulse animated': missionsCardAnimated }"
      >
        <DashMissions
          :active-missions="activeMissions"
          :active-missions-count="activeMissionsCount"
        />
      </v-col>
      <v-col
        v-if="hasMissionFeature && isAdminProfile"
        cols="12"
        md="6"
        class="pa-3 d-flex flex-column"
      >
        <v-card class="card" elevation="3" outlined>
          <LeafletMap class="leaflet-container" :markers="markers" />
        </v-card>
      </v-col>
      <v-col v-if="hasScenarioFeature && isAdminProfile" cols="12" class="pa-3 d-flex flex-column">
        <DashScenarios :scenarios="scenarios" />
      </v-col>
      <v-col cols="12" class="pa-3 d-flex flex-column">
        <dash-bar-chart :chart-data="chartData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '@/util/auth';
import api from '@/util/api';
import { isAdmin } from '@/consts/roles';
import DashAlerts from './DashAlerts';
import DashSummary from './DashSummary';
import DashReminders from './DashReminders';
import DashMissions from './DashMissions';
import DashScenarios from './DashScenarios';
import DashBarChart from './DashBarChart';
import getUserProfile from '@/util/getUserProfile';
import LeafletMap from '@/components/LeafletMap.vue';
import * as colors from '@/lib/ui/colors';
import moment from 'moment';

export default {
  name: 'Dashboard',

  components: {
    DashAlerts,
    DashSummary,
    DashReminders,
    DashMissions,
    DashScenarios,
    DashBarChart,
    LeafletMap,
  },
  loading: false,

  props: {},

  data: () => ({
    profile: {},
    hasDevices: false,
    usersCount: 0,
    usersWithoutDevices: [],
    markers: [],
    alertSlider: 0,
    latestAlerts: [],
    activeAlertsCount: 0,
    activeMissionsCount: 0,
    activeMissions: [],
    scenarios: [],
    chartData: {
      datasets: [],
    },
    loaded: false,
    events: [],
    alertsCardAnimated: false,
    missionsCardAnimated: false,
    hasMissionFeature: false,
    hasScenarioFeature: false,
  }),

  computed: {
    showReminder() {
      if (this.usersWithoutDevices.length === 0) {
        return false;
      } else {
        let percentage = this.usersWithoutDevices.length / this.usersCount;
        return percentage > 0.2;
      }
    },
    isAdminProfile() {
      return isAdmin(this.profile.user.role);
    },
  },

  created() {
    this.getDashboardData();
  },

  mounted() {
    this.subscribeToSignalREvents(auth.getCustomerId());
  },

  beforeDestroy() {
    this.unregisterSignalREvents();
  },

  methods: {
    async getDashboardData() {
      getUserProfile().then((profile) => {
        this.profile = profile;
        this.hasDevices = profile.devices.length !== 0;
        // check profile for enabled features
        this.profile.features.forEach((feature) => {
          if (feature.featureName === 'mission') {
            this.hasMissionFeature = true;
          } else if (feature.featureName === 'scenario') {
            this.hasScenarioFeature = true;
          }
        });
        api.getDashData().then((dashData) => {
          let alertData = [];
          const chartData = {
            datasets: [
              {
                data: alertData,
                backgroundColor: [colors.blueDark],
              },
            ],
          };
          const dateFormat = 'YYYY-MM-DD';
          const NUM_OF_DAYS = 91; // get last 90 dates.
          for (let i = 0; i < NUM_OF_DAYS; i++) {
            let date = moment().subtract(90, 'day');
            date.add(i, 'day');
            let existingData = dashData.chartData.find(
              (element) => moment(element.date).format(dateFormat) === date.format(dateFormat),
            );

            if (existingData) {
              alertData.push({ x: date.format(dateFormat), y: existingData.count });
            } else {
              alertData.push({ x: date.format(dateFormat), y: 0 });
            }
          }
          this.chartData = chartData;
          this.usersCount = dashData.usersCount;
          this.usersWithoutDevices = dashData.usersWithoutDevices;
          this.activeAlertsCount = dashData.activeAlertsCount;
          this.activeMissions = dashData.activeMissions;
          this.activeMissionsCount = dashData.activeMissionsCount;
          this.scenarios = dashData.scenarios;
          this.markers = dashData.positions.map((a) => ({
            position: new Array(a.lat, a.lng),
            userName: a.userName,
            deviceName: a.deviceName,
          }));

          this.latestAlerts = dashData.latestAlerts.map((alert) => ({
            id: alert.id,
            date: alert.created,
            title: alert.title,
            message: alert.message,
            user: alert.user,
            type: alert.alertType,
            isLocationRestricted: alert.isLocationRestricted,
            containsAuthorLocation: alert.containsAuthorLocation,
          }));
          this.loaded = true;
        });
      });
    },
    subscribeToSignalREvents(customerId) {
      let self = this;
      // Unregister all events
      this.events.forEach((event) => this.$signalR.off(event));

      this.events = [
        `newMissionCustomerId:${customerId}`,
        `missionFinishedCustomerId:${customerId}`,
        `newAlertCustomerId:${customerId}`,
      ];

      this.events.forEach((event) =>
        this.$signalR.on(event, () => {
          setTimeout(() => {
            this.getDashboardData();
            if (event === `newAlertCustomerId:${customerId}`) {
              self.alertsCardAnimated = true;
              setTimeout(function () {
                self.alertsCardAnimated = false;
              }, 1000);
            }
            if (
              event === `newMissionCustomerId:${customerId}` ||
              event === `missionFinishedCustomerId:${customerId}`
            ) {
              self.missionsCardAnimated = true;
              setTimeout(function () {
                self.missionsCardAnimated = false;
              }, 1000);
            }
          }, 2000); // Needs timeout to wait for the system to update backend
        }),
      );
    },
    unregisterSignalREvents() {
      this.events.forEach((event) => this.$signalR.off(event));
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 12px;
}

.leaflet-container {
  border-radius: 12px !important;
  height: 310px;
  position: relative;
}

.animated {
  -webkit-animation-duration: 600ms;
  animation-duration: 600ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.04, 1.04, 1.04);
    transform: scale3d(1.04, 1.04, 1.04);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.04, 1.04, 1.04);
    transform: scale3d(1.04, 1.04, 1.04);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
</style>
