<template>
  <div class="contents">
    <v-card v-if="!hasDevices || showReminder" class="card pa-4 flex" elevation="3" outlined>
      <v-row justify="center">
        <v-card-title>
          <v-icon class="mr-4">fal fa-hand-point-ribbon</v-icon>{{ $t('reminders') }}</v-card-title
        >
      </v-row>
      <div v-if="!hasDevices" class="ma-4">
        <p>{{ $t('youHaveNoDevices') }}</p>
        <AppLinks class="mt-8" />
      </div>
      <div v-if="showReminder && hasDevices" class="ma-4">
        <p>
          {{ $t('usersWithoutDevices', null, { usersWithoutDevices: usersWithoutDevices.length }) }}
        </p>
        <v-col class="d-flex justify-center">
          <v-btn color="primary" elevation="1" @click="openReminderDialog">
            {{ $t('sendReminder') }}
          </v-btn>
        </v-col>
      </div>
    </v-card>

    <v-dialog v-model="showReminderDialog" max-width="500" @click:outside="closeReminderDialog">
      <v-card>
        <v-card-title class="white--text info">
          <v-icon color="white" class="mx-5">fal fa-hand-point-ribbon</v-icon>
          <span>{{ $t('sendReminder') }}</span>
        </v-card-title>
        <v-card-text>
          <v-list flat subheader>
            <v-subheader>{{ $t('sendReminderHeader') }}</v-subheader>
            <v-list-item-group v-model="usersToRemind" multiple active-class="">
              <v-list-item
                v-for="(user, index) in usersWithoutDevices"
                :key="index"
                :value="user.id"
              >
                <template #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-progress-linear v-if="sendingReminder" indeterminate color="teal" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="closeReminderDialog">
            {{ $t('close') }}
          </v-btn>
          <v-btn color="primary" :disabled="usersToRemind.length === 0" @click="sendReminder">
            {{ $t('send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/util/api';
import genericErrorMessage from '@/util/genericErrorMessage';
import AppLinks from '@/components/AppLinks';

export default {
  name: 'DashReminders',

  components: {
    AppLinks,
  },
  props: {
    hasDevices: {
      type: Boolean,
      required: true,
      default: () => true,
    },
    showReminder: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    usersWithoutDevices: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data: () => ({
    showReminderDialog: false,
    usersToRemind: [],
    sendingReminder: false,
  }),

  methods: {
    openReminderDialog() {
      this.showReminderDialog = true;
    },

    closeReminderDialog() {
      this.usersToRemind = [];
      this.showReminderDialog = false;
      this.sendingReminder = false;
    },

    sendReminder() {
      this.sendingReminder = true;
      api
        .sendAppDownloadReminder(this.usersToRemind)
        .then(() => {
          this.usersToRemind = [];
          this.showReminderDialog = false;
          this.sendingReminder = false;
        })
        .catch((res) => genericErrorMessage(res));
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 12px;
}
</style>
