<template>
  <v-container>
    <div class="d-flex flex-column">
      <span class="align-self-center">{{ name }}</span>
      <profile-photo
        v-if="photo !== null"
        class="align-self-center"
        :url="photo"
        :alt="name"
        :size="'110px'"
        :light-border="true"
        :clickable="false"
      />
      <div class="align-self-center">{{ missionStatus }}</div>
    </div>
  </v-container>
</template>

<script>
import ProfilePhoto from '@/components/ProfilePhoto.vue';

const secondInMilliseconds = 1000;
const minuteInMilliseconds = 60 * secondInMilliseconds;
const hourInMilliseconds = 60 * minuteInMilliseconds;
const dayInMilliseconds = 24 * hourInMilliseconds;

export default {
  name: 'MissionCountdown',

  components: {
    ProfilePhoto,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    photo: {
      type: String,
      required: false,
      default: undefined,
    },
    endTime: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    timer: null,
    remainingTime: '',
  }),

  computed: {
    end() {
      return new Date(this.endTime);
    },
    missionStatus() {
      return this.timer === null ? this.$t('missionExpired') : this.remainingTime;
    },
  },

  watch: {
    endTime() {
      this.startTimer();
    },
  },

  mounted() {
    this.startTimer();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    startTimer() {
      if (this.timer === null) {
        this.setRemainingTime();
        this.timer = setInterval(this.setRemainingTime, 1000);
      }
    },

    setRemainingTime() {
      const now = new Date();
      const difference = this.end.getTime() - now.getTime();

      if (difference < 0) {
        clearInterval(this.timer);
        this.timer = null;
      } else {
        const days = Math.floor(difference / dayInMilliseconds);
        const hours = Math.floor((difference % dayInMilliseconds) / hourInMilliseconds);
        const minutes = Math.floor((difference % hourInMilliseconds) / minuteInMilliseconds);
        const seconds = Math.floor((difference % minuteInMilliseconds) / secondInMilliseconds);

        const pad = (num) => (num < 10 ? '0' + num : num);

        this.remainingTime = `${pad(days)} : ${pad(hours)} : ${pad(minutes)} : ${pad(seconds)}`;
      }
    },
  },
};
</script>
