import { warnLog, errLog } from './../lib/util/logger';
import is from './../lib/util/is';
import english from './languages/en-us.json';
import spanish from './languages/es-es.json';
import norwegian from './languages/no-nb.json';
import danish from './languages/da-dk.json';
import swedish from './languages/sv-se.json';
import moment from 'moment';

const i18n = {};

const defaultLang = 'en-us';
let currentLang = defaultLang;

const translations = {
  'en-us': english,
  'es-es': spanish,
  'no-nb': norwegian,
  'da-dk': danish,
  'sv-se': swedish,
};

export const translate = (i18n.translate = (key, specificLang, replaceMap) => {
  let lang = currentLang;

  // Alternatively use a specific language for this translation only
  if (specificLang && translations[specificLang]) {
    lang = specificLang;
  }

  // Translation with selected language
  let retVal = translations[lang][key];

  if (!retVal && lang !== defaultLang) {
    warnLog(`No translation found for key "${key}" in language "${lang}".`);
    // Fallback to default language
    retVal = translations[defaultLang][key];
  }

  if (!retVal) {
    warnLog(`No translation found for key "${key}" in language "${defaultLang}" (default).`);
    // Fallback to key
    retVal = key;
  }

  if (is.string(retVal) && is.object(replaceMap)) {
    Object.keys(replaceMap).forEach(
      (variable) =>
        // Replace all
        (retVal = retVal.split(`{{${variable}}}`).join(replaceMap[variable])),
    );
  }

  return retVal;
});

export const getLanguages = (i18n.getLanguages = () =>
  Object.keys(translations).map((langCode) => ({
    code: langCode,
    name: i18n.translate('lang', langCode),
    flag: i18n.translate('flag', langCode),
    current: langCode === currentLang,
  })));

const langHandle = 'teamAlertLang';

const getLanguageFromQueryParam = () => {
  const params = new URLSearchParams(location.search);
  const langQueryParam = params.get('lang');
  if (langQueryParam == null) {
    return null;
  }

  if (Object.keys(translations).indexOf(langQueryParam) < 0) {
    return null;
  }

  params.delete('lang');
  let newParams = params.toString();
  if (newParams.length > 0) {
    newParams = '?' + newParams;
  }
  const newLocation = location.origin + location.pathname + newParams;
  history.replaceState({}, document.title, newLocation);

  return langQueryParam;
};

const getLanguageFromLocalStorage = () => {
  let lang;
  try {
    lang = localStorage.getItem(langHandle);
  } catch (exception) {
    errLog("Couldn't read from localStorage");
    lang = defaultLang;
  }

  if (is.defined(lang) && Object.keys(translations).indexOf(lang) >= 0) {
    return lang;
  } else {
    try {
      localStorage.setItem(langHandle, defaultLang);
    } catch (exception) {
      errLog("Couldn't write to localStorage");
    }
    return defaultLang;
  }
};

const storeLanguageToLocalStorage = (language, refresh) => {
  try {
    localStorage.setItem(langHandle, language);
  } catch (exception) {
    errLog("Couldn't write to localStorage");
  }
  if (refresh !== false) {
    location.reload();
  }
};

currentLang = getLanguageFromQueryParam();
if (!currentLang) {
  currentLang = getLanguageFromLocalStorage();
} else {
  storeLanguageToLocalStorage(currentLang, false);
}
export const getLanguage = (i18n.getLanguage = () => currentLang);

const updateMomentLanguage = (newLang) => {
  const momentLang = newLang || getLanguage();
  switch (momentLang) {
    case 'no-nb':
      moment.locale('nb');
      break;
    case 'da-dk':
      moment.locale('da');
      break;
    case 'es-es':
      moment.locale('es');
      break;
    case 'en-us':
    default:
      moment.locale('en');
  }
};

export const switchLanguage = (i18n.switchLanguage = (language, refresh) => {
  const langSupported = Object.keys(translations).indexOf(language) >= 0;

  if (langSupported) {
    currentLang = language;

    storeLanguageToLocalStorage(language, refresh);
  } else {
    errLog(`No translations found for language "${language}"`);
  }

  updateMomentLanguage(currentLang);

  return currentLang;
});

updateMomentLanguage();

export default i18n;
