<template>
  <div class="d-flex align-center justify-center">
    <template v-for="(appLink, index) in appLinks">
      <a :key="index" :href="appLink.href" class="app-link mx-2">
        <img :src="appLink.src" :alt="appLink.alt" width="190px" height="60px" />
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppLinks',

  data: () => ({
    appLinks: [
      {
        href: 'https://itunes.apple.com/no/app/teamalert/id1354897593?l=nb&amp;mt=8',
        src: require('@/assets/images/appstore.png'),
        alt: 'Download on the AppStore',
      },
      {
        href: 'https://play.google.com/store/apps/details?id=no.framweb.teamalert',
        src: require('@/assets/images/google-play.png'),
        alt: 'Get it on Google Play',
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.app-link {
  display: inline-block;
}
</style>
