import api from './api';
import errorMessage from './errorMessage';
import { translate as t } from './i18n';
import moment from 'moment';
import auth from './auth';

let profile;

let loading = false;

const error = (res) => errorMessage(t('getUserProfileError'), t('getUserProfileErrorMsg'), res);

/**
 * Gets the user profile
 *
 * @param {boolean} [forceRefresh] whether or not to force a refresh. Defaults to false
 */
const getUserProfile = (forceRefresh = false) =>
  new Promise((resolve) => {
    const loadFromAPI = () => {
      loading = true;
      Promise.all([api.getMe(), api.getWebAppFeatures()])
        .then((results) => {
          const me = results[0];
          const features = results[1].features;
          profile = {
            ...me,
            features,
          };
          loading = false;
          resolve(profile);
        })
        .catch((res) => {
          loading = false;
          if (auth.loggedIn()) {
            error(res);
          }
        });
    };

    if (!profile || forceRefresh) {
      if (loading) {
        const ts = moment();
        const id = setInterval(() => {
          const twoSecondsAgo = moment().subtract(2, 'seconds');
          if (profile) {
            clearInterval(id);
            resolve(profile);
          } else if (ts.isBefore(twoSecondsAgo)) {
            clearInterval(id);
            loadFromAPI();
          }
        }, 500);
      } else {
        loadFromAPI();
      }
    } else {
      resolve(profile);
    }
  });

export default getUserProfile;
