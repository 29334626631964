import Vue from 'vue';
import dialog from '@/components/dialog/dialog';
import HtmlDialog from '@/components/dialog/HtmlDialog.vue';

const htmlDialog = (htmlElement) =>
  dialog({
    props: {
      htmlElement,
    },
    component: Vue.extend(HtmlDialog),
  });

export default htmlDialog;
