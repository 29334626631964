const formatByteSize = (byteSize) => {
  if (byteSize < 1024) {
    return `${byteSize} B`;
  }
  if (byteSize < 1024 * 1024) {
    return `${(byteSize / 1024).toFixed(2)} KB`;
  }
  if (byteSize < 1024 * 1024 * 1024) {
    return `${(byteSize / 1024 / 1024).toFixed(2)} MB`;
  }
  return `${(byteSize / 1024 / 1024 / 1024).toFixed(2)} GB`;
};

export default formatByteSize;
