import ajax from './ajax';
import auth from './auth';
import moment from 'moment';

/********************
 * Helper functions *
 ********************/

const mapAlert = (alert) => ({
  id: alert.alertId,
  type: alert.alertType.replace('alert', 'urgentAlert'),
  status: alert.status,
  message: alert.message,
  date: moment(alert.sentTS),
  simulationMode: alert.simulationMode,
  title: alert.title,
  containsAuthorLocation: alert.containsAuthorLocation,
  isLocationRestricted: alert.isLocationRestricted,
});

/**
 * @typedef Paging
 * @property {number} page 1-counter page index
 * @property {number} pageSize the amount of rows per page
 */

/**
 *
 * @param {Paging} paging
 */
const pagingToQuery = (paging) =>
  `pageSize=${paging.pageSize || 25}&page=${paging.page || 1}&includeTotal=true`;

const listToQuery = (property, list) => {
  let query = '';
  list.forEach((item, i) => {
    query += `${property}=${item}`;
    if (i < list.length - 1) {
      query += '&';
    }
  });
  return query;
};

const api = {
  /*********
   * Alert *
   *********/

  /**
   * Saves an alert draft
   */
  saveDraft: (form) =>
    new Promise((resolve, reject) =>
      ajax({
        url: '/api/alerts/' + (form.severity === 'alarm' ? 'alarmDraft' : 'alertDraft'),
        type: 'POST',
        body: form,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        }),
    ),

  /**
   * Sends an alert
   */
  sendAlert: (form) =>
    ajax({
      url: '/api/alerts/' + (form.severity === 'alarm' ? 'alarm' : 'alert'),
      type: 'POST',
      body: form,
    }),

  /**
   * Updates an alert
   */
  updateAlert: (alertId, form) =>
    ajax({
      url: `/api/alerts/${alertId}`,
      type: 'PUT',
      body: form,
    }),
  /**
   * Deletes an alert draft
   * @param {number} alertId the ID of the alert to delete
   */
  deleteDraft: (alertId) =>
    ajax({
      url: `/api/deleteDraft/${alertId}`,
      type: 'DELETE',
    }),
  /**
   * Gets user alerts
   * @param {Object} form the form for getting alerts
   * @param {string[]} form.statuses the alert statuses to filter on
   * @param {number} [form.page] the page to retrieve. Defaults to 0
   * @param {number} [form.pageSize] the maximum number of rows to retrieve per page. Defaults to 25
   */
  getMyAlerts: (form) =>
    new Promise((resolve, reject) =>
      ajax({
        url: `/api/alerts/mine?${listToQuery('statuses', form.statuses)}&${pagingToQuery(form)}`,
        type: 'GET',
      })
        .then((res) => {
          res.alerts = res.alerts.map(mapAlert);
          resolve(res);
        })
        .catch((res) => reject(res)),
    ),

  /**
   * Gets all customer alerts
   * @param {Object} form the form for getting alerts
   * @param {string[]} form.statuses the alert statuses to filter on
   * @param {number} [form.page] the page to retrieve. Defaults to 0
   * @param {number} [form.pageSize] the maximum number of rows to retrieve per page. Defaults to 25
   */
  getAllAlerts: (form) =>
    new Promise((resolve, reject) =>
      ajax({
        url: `/api/alerts/all?${listToQuery('statuses', form.statuses)}&${pagingToQuery(form)}`,
        type: 'GET',
      })
        .then((res) => {
          res.alerts = res.alerts.map(mapAlert);
          resolve(res);
        })
        .catch((res) => reject(res)),
    ),

  /**
   * Gets a single alert identified by its ID
   * @param {number} alertId the ID of the alert to retrieve
   */
  getAlert: (alertId) =>
    new Promise((resolve, reject) =>
      ajax({
        url: `/api/alerts/${alertId}`,
        type: 'GET',
      })
        .then((alert) => {
          const target = alert.alert.target;
          const missionId = alert.alert.missionId;
          const isMissionAlarm = alert.alertType === 'missionAlarm';
          const isLocationAlert = target.polygon !== null || target.locations?.length > 0;
          return resolve({
            attachments: alert.attachments,
            author: alert.userName,
            authorId: alert.userId,
            authorProfilePhoto: alert.profilePhoto,
            date: moment(alert.sentTS),
            id: alert.alertId,
            isLocationAlert,
            isMissionAlarm,
            message: alert.alert.message.content,
            missionId,
            position: alert.alert.position,
            serverData: alert,
            simulationMode: alert.alert.simulationMode,
            status: alert.status,
            stats: alert.stats,
            title: alert.alert.message.title,
            type: alert.alertType.replace('alert', 'urgentAlert'),
            target: alert.alert.target,
          });
        })
        .catch((res) => reject(res)),
    ),

  /**
   * Get the messages sent to recipients of an alert
   *
   * @param {number} alertId the ID of the alert
   */
  getAlertMessages: (alertId) =>
    new Promise((resolve, reject) =>
      ajax({
        // TODO: Swap this config with the config below when new route is
        // implemented. The new route will return a different data structure to
        // unify the counts shown in all clients.

        // url: `/api/alerts/${alertId}/messages?${pagingToQuery({ page: 1, pageSize: 1000 })}`,
        // type: 'GET',
        url: '/api/getAlertMessages',
        body: {
          alertId,
          page: 1,
          pageSize: 1000,
        },
        type: 'POST',
      })
        .then((res) => {
          res.messages.forEach((message) => {
            message.updateTS = moment(message.updateTS);
          });

          resolve(res);
        })
        .catch((res) => reject(res)),
    ),

  /**
   * Deletes an alert
   *
   * @param {number} alertId the ID of the alert to delete
   */
  deleteAlert: (alertId) =>
    ajax({
      url: `/api/alerts/${alertId}`,
      type: 'DELETE',
    }),

  getAlertLogExport: (from, to) =>
    ajax({
      url: `/api/alerts/export?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`,
      type: 'GET',
      responseType: 'blob',
    }),

  /*********
   * AlertTarget *
   *********/

  /**
   * Gets users for given target
   * @param {Object} target selected target for the alert
   */
  getTargetReach: (target) =>
    ajax({
      url: `/api/alerts/target/reach`,
      type: 'POST',
      body: {
        alertTarget: target,
      },
    }),

  /***************
   * Positioning *
   ***************/

  /**
   * Sends a positioning request to teams or a location
   *
   * @param {Object} form the positioning form
   * @param {boolean} company whether or not to position the entire company
   * @param {number[]} form.teams the teams to position
   * @param {number[]} form.locations the locationIds (predefined polygons) to position
   * @param {string} form.polygon the new polygon to position
   */
  sendPositioning: (form) =>
    ajax({
      url: '/api/positionings',
      type: 'POST',
      body: {
        reason: form.reason,
        target: {
          company: form.company,
          teams: form.teams,
          locations: form.locations,
          adHocLocation: form.polygon,
        },
      },
    }),

  /**
   * Gets all positionings that targeted the current user
   *
   * @param {Paging} paging
   */
  getUserPositionings: (paging) =>
    ajax({
      url: `/api/positionings/mine?${pagingToQuery(paging)}`,
      type: 'GET',
    }),

  /**
   * Gets all positionings that targeted the current user
   *
   * @param {Paging} paging
   */
  getAllPositionings: (paging) =>
    ajax({
      url: `/api/positionings/all?${pagingToQuery(paging)}`,
      type: 'GET',
    }),

  getDevicePositioningStats: (alertId) =>
    ajax({
      url: `/api/positionings/${alertId}/stats/devices`,
      type: 'GET',
    }),

  getUserPositioningStats: (alertId) =>
    ajax({
      url: `/api/positionings/${alertId}/stats/users`,
      type: 'GET',
    }),

  getPositioningDevices: (alertId) =>
    ajax({
      url: `/api/positionings/${alertId}/devices`,
      type: 'GET',
    }),

  /***********
   * Comment *
   ***********/

  /**
   * Sends an update on an alert, and can optionally close it
   *
   * @param {object} form the alert update form
   * @param {number} form.id the ID of the alert to update
   * @param {string} [form.message] an optional message to send to the recipients of the alert
   * @param {string[]} [form.attachments] optional attachments to send to the recipients of the alert
   * @param {boolean} form.close whether or not to close the alert
   */
  sendAlertUpdate: (form) =>
    ajax({
      url: `/api/alerts/${form.id}/comments/update`,
      type: 'POST',
      body: {
        message: form.message,
        attachments: form.attachments,
        complete: form.close,
      },
    }),

  /**
   * Adds a comment to an alert
   * @param {object} form the comment form
   * @param {number} form.alertId the ID of the alert to add a comment to
   * @param {string} form.message the message to add
   * @param {string} form.attachments the attachments to add
   */
  addComment: (form) =>
    ajax({
      url: `/api/alerts/${form.alertId}/comments`,
      type: 'POST',
      body: {
        message: form.message,
        attachments: form.attachments,
      },
    }),

  getAlertAttachmentUploadlink: (alertId, extension) =>
    ajax({
      url: `/api/alerts/${alertId}/comments/attachmentUploadUrl/${extension}`,
      type: 'GET',
    }),

  getAlertAttachmentDeleteUrl: (alertId, extension) =>
    ajax({
      url: `/api/alerts/${alertId}/comments/attachmentDeleteUrl/${extension}`,
      type: 'GET',
    }),

  /**
   * Gets the timeline items for an alert
   *
   * @param {object} form the timeline form
   * @param {number} form.page the page index to retrieve
   * @param {number} form.pageSize the number of rows to retrieve
   * @param {number} form.alertId the ID of the alert to get the timeline of
   */
  getComments: (form) =>
    new Promise((resolve, reject) =>
      ajax({
        url: `/api/alerts/${form.alertId}/comments?${pagingToQuery(form)}`,
        type: 'GET',
      })
        .then((res) => {
          res.comments = res.comments.map((item) => ({
            author: item.userName,
            authorId: item.userId,
            authorProfilePhoto: item.userProfilePhoto,
            time: moment(item.created),
            type: item.messageType.replace('alert', 'update'),
            message: item.message,
            id: item.timelineId,
            attachments: item.attachments,
          }));

          resolve(res);
        })
        .catch((res) => reject(res)),
    ),

  /*********
   * Login *
   *********/

  /**
   * Logs in the user with email and password
   * @param {object} form          the login form
   * @param {string} form.username the user's email
   * @param {string} form.password the user's password
   * @param {string} form.extendedLogin whether or not to extend the login
   */
  login: (form) =>
    ajax({
      url: '/api/login',
      type: 'POST',
      body: form,
    }),

  /**
   * Logs out the user
   */
  logout: () => {
    auth.logout();
    return ajax({
      url: '/api/logout',
      type: 'PUT',
    });
  },

  /**
   * Generates a time secret to use with 2FA
   */
  generateTimeSecret: () =>
    ajax({
      url: `/api/me/otp/totp-secret`,
      type: 'GET',
    }),

  /**
   * Logs in the user with email, password and one-time code
   * @param {object} form          the login form
   * @param {string} form.username the user's email
   * @param {string} form.password the user's password
   * @param {string} form.extendedLogin whether or not to extend the login
   * @param {string} form.code     the one-time code
   */
  verifyTimeCode: (form) =>
    ajax({
      url: '/api/login/totp',
      type: 'POST',
      body: form,
    }),

  /**
   * Verifies the otp code when logged in to check whether the code was
   * generated correctly
   *
   * @param {string} otp the otp code
   */
  validateTotpCode: (otp) =>
    ajax({
      url: '/api/me/otp/totp-secret',
      type: 'POST',
      body: {
        otp,
      },
    }),

  /******
   * Me *
   ******/

  /**
   * Gets information about the current user
   */
  getMe: () =>
    ajax({
      url: '/api/me',
      type: 'GET',
    }),

  getProfilePhotoUploadLink: () =>
    ajax({
      url: '/api/me/profilePhotoUploadLink',
      type: 'GET',
    }),

  /**
   * Updates the user's name
   */
  updateUsername: (newName) =>
    ajax({
      url: '/api/me/name',
      type: 'PUT',
      body: {
        name: newName,
      },
    }),

  /**
   * Updates the current user's sos list
   *
   * @param {object[]} sosList the new list of ICE contacts
   */
  updateMySos: (sosList) =>
    ajax({
      url: '/api/sosContacts',
      type: 'PUT',
      body: {
        sos: {
          list: sosList,
        },
      },
    }),

  /**
   * Deletes the user account for the current user
   */
  deleteMe: (usernameVerification) =>
    ajax({
      url: `/api/me?usernameVerification=${usernameVerification}`,
      type: 'DELETE',
    }),

  /**
   * Updates the 2FA method used
   *
   * @param {string} method - one of 'none', 'totp' or 'sms'
   */
  updateTFAMethod: (method) =>
    ajax({
      url: '/api/me/otp',
      type: 'PUT',
      body: {
        use2FA: method,
      },
    }),

  /************
   * Password *
   ************/

  /**
   * Sends an email to the user with a password reset link if the email exists
   * in the database.
   *
   * @param {string} email the email address to send to
   */
  startResetPassword: (email) =>
    ajax({
      url: '/api/password/reset',
      type: 'PUT',
      body: {
        email,
      },
    }),

  /**
   * Sets a new password for a user, by using a token received by email.
   *
   * @param {object} form the completeResetPassword form
   * @param {string} form.code the token received by email, identifying the user
   * @param {string} form.newPassword the new password to set for the user
   */
  completeResetPassword: (form) =>
    ajax({
      url: '/api/password/update',
      type: 'PUT',
      body: form,
    }),

  /********
   * User *
   ********/

  /**
   * Gets all the users that the logged in user has access to
   */
  getUsers: () =>
    new Promise((resolve, reject) =>
      ajax({
        url: `/api/users?${pagingToQuery({ page: 1, pageSize: 1000 })}`,
        type: 'GET',
      })
        .then((res) => {
          res.users.forEach((user) => (user.id = user.userId));
          resolve(res);
        })
        .catch((res) => reject(res)),
    ),

  /**
   * Gets all the users that the logged in user has access to, including soft-deleted users
   */
  getUsersWithSoftDeleted: () =>
    new Promise((resolve, reject) =>
      ajax({
        url: `/api/users/with-soft-deleted?${pagingToQuery({ page: 1, pageSize: 1000 })}`,
        type: 'GET',
      })
        .then((res) => {
          res.users.forEach((user) => (user.id = user.userId));
          resolve(res);
        })
        .catch((res) => reject(res)),
    ),

  deleteUser: (userId) =>
    ajax({
      url: `/api/accounts/${userId}/soft-delete`,
      type: 'DELETE',
    }),

  restoreUser: (userId) =>
    ajax({
      url: `/api/accounts/${userId}/restore`,
      type: 'PATCH',
    }),

  /**
   * Updates the name and role of a user
   * @param {object} form the role update form
   * @param {number} form.userId the ID of the user to update role for
   * @param {string} form.name the new name to set for the user
   * @param {string} form.role the new role to set for the user
   */
  updateUserNameAndRole: (form) =>
    ajax({
      url: `/api/accounts/${form.userId}`,
      type: 'PUT',
      body: form,
    }),

  validateImport: (users) =>
    ajax({
      url: '/api/accounts',
      type: 'PUT',
      body: {
        users,
      },
    }),

  addUsers: (users) =>
    ajax({
      url: '/api/accounts',
      type: 'POST',
      timeout: 1000 * 30,
      body: {
        users,
      },
    }),

  /**
   * Sends an email to users without devices reminding them to download and set
   * up the TeamAlert app.
   */
  sendAppDownloadReminder: (userIds) =>
    ajax({
      url: '/api/users/app-download-reminder',
      type: 'POST',
      body: {
        userIds,
      },
    }),

  /************
   * Customer *
   ************/

  createNewCustomer: (name) =>
    ajax({
      url: '/api/customer',
      type: 'POST',
      body: {
        name,
      },
    }),

  /**
   * Permanently deletes a customer and all their assets
   */
  deleteCustomer: () =>
    ajax({
      url: '/api/customer',
      type: 'DELETE',
    }),

  /**
   * Gets data to use in the dash
   */
  getDashData: () =>
    ajax({
      url: '/api/dash/data',
      type: 'GET',
    }),

  getWebAppFeatures: () =>
    ajax({
      url: '/api/features/web',
      type: 'GET',
    }),

  getCustomerSettings: () =>
    ajax({
      url: '/api/customerSettings',
      type: 'GET',
    }),

  getCustomerName: (id) =>
    ajax({
      url: `/api/customer/name/${id}`,
      type: 'GET',
    }),

  /**
   * Sets a setting for the customer
   *
   * @param {string} key
   * @param {string} value
   */
  setCustomerSetting: (key, value) =>
    ajax({
      url: `/api/customerSettings/${key}`,
      type: 'POST',
      body: {
        value,
      },
    }),

  /**
   * Sets a name for the customer
   *
   * @param {string} name   */
  setCustomerName: (name) =>
    ajax({
      url: '/api/customer/name',
      type: 'PUT',
      body: {
        name: name,
      },
    }),

  /**
   * Gets the customer's voice profile
   */
  getCustomerVoiceProfile: () =>
    ajax({
      url: '/api/customer/voiceprofile',
      type: 'GET',
    }),

  /**
   * Updates a customer's voice profile
   *
   * @param {object} form the voice profile form
   * @param {string} form.voice the voice ID to use for voice calls
   * @param {string} form.voiceIntro the introduction message to read for all
   *                                 voice calls
   * @param {string} form.conferenceMessage the message to read for conference
   *                                        call invitations
   * @param {string} form.conferenceJoin the instruction to press key 1 to
   *                                     join a conference call
   */
  updateCustomerVoiceProfile: (form) =>
    ajax({
      url: '/api/customer/voiceprofile',
      type: 'PUT',
      body: {
        voiceId: form.voice,
        messageIntroduction: form.voiceIntro,
        messageIntrodction: form.voiceIntro,
        messageConfirm: 'OK',
        messageCallConf: form.conferenceMessage,
        messageKey1: form.conferenceJoin,
      },
    }),

  /**
   * Gets all available voices
   */
  getVoices: () =>
    ajax({
      url: '/api/voices',
      type: 'GET',
    }),

  /**
   * Gets an audio sample of the currently selected voice
   *
   * @param {string} voiceId
   * @param {string} text
   */
  getVoiceAudioSample: (voiceId, text) =>
    ajax({
      url: `/api/voices/getVoiceAudioSample/${voiceId}/${text}`,
      type: 'GET',
    }),

  /**
   * Creates a new customer.
   *
   * @param {object} form the new customer form
   * @param {string} form.companyName the name of the company
   * @param {string} form.adminName the name of the company's admin
   * @param {string} form.adminEmail the email of the company's admin
   * @param {string} form.adminMobile the mobile phone number of the company's
   *                                  admin
   */
  signup: (form) =>
    ajax({
      url: '/api/signup',
      type: 'POST',
      body: form,
    }),

  /**
   * Activates a customer and sets the password for the owner account
   *
   * @param {object} form the customer activation form
   * @param {string} form.code the reference code from the URL
   * @param {string} form.password the password to set for the owner account
   * @param {boolean} form.userExists whether or not the user already exists
   */
  activateCustomer: (form) =>
    ajax({
      url: '/api/signup/activate',
      type: 'POST',
      body: form,
    }),

  /**
   * Closes a customer and sets it up for deletion after 30 days
   *
   * @param {string} customerNameVerification the customer name for verification
   */
  closeCompany: (customerNameVerification) =>
    ajax({
      url: `/api/customer?customerNameVerification=${customerNameVerification}`,
      type: 'DELETE',
    }),

  /********
   * Team *
   ********/

  /**
   * Gets all teams available for the logged in user
   * @param {Number} profilePhotosCount the number of member profile photo urls
   *                                    to include
   */
  getTeams: (profilePhotosCount = 0) =>
    ajax({
      url: `/api/teams?profilePhotosCount=${profilePhotosCount}&${pagingToQuery({
        page: 1,
        pageSize: 1000,
      })}`,
      type: 'GET',
    }),

  /**
   * @param {number[]} teamIds the teamIds to count the unique members from
   */
  getUniqueMemberCount: (teamIds) => {
    let teamIdList = '';
    teamIds.forEach((teamId, i) => {
      teamIdList += 'ids=' + teamId;
      if (i < teamIds.length - 1) {
        teamIdList += '&';
      }
    });

    return ajax({
      url: `/api/teams/unique-member-count?${teamIdList}`,
      type: 'GET',
    });
  },

  /**
   * Creates a team in the company
   *
   * @param {object} form the form for creating a new team
   * @param {string} form.name the name of the team
   * @param {string} [form.description] the description of the team
   */
  createTeam: (form) =>
    ajax({
      url: '/api/teams',
      type: 'POST',
      body: form,
    }),

  /**
   * Deletes a team from the company
   *
   * @param {number} teamId the ID of the team to remove
   */
  deleteTeam: (teamId) =>
    ajax({
      url: `/api/teams/${teamId}`,
      type: 'DELETE',
    }),

  /**
   * Updates a team
   *
   * @param {object} form the team update form
   * @param {number} form.id the ID of the team to update
   * @param {string} form.name the name of the team
   * @param {string} [form.description] the description of the team
   */
  updateTeam: (form) =>
    ajax({
      url: `/api/teams/${form.id}`,
      type: 'PUT',
      body: {
        name: form.name,
        description: form.description,
      },
    }),

  /**
   * Gets a team by its ID
   *
   * @param {number} teamId the ID of the team to retrieve
   */
  getTeam: (teamId) =>
    ajax({
      url: `/api/teams/${teamId}`,
      type: 'GET',
    }),

  /**
   * Adds members to a team
   *
   * @param {object} form the addTeamMembers form
   * @param {number} form.teamId the ID of the team to add members to
   * @param {number[]} form.users the users IDs to add
   */
  addTeamMembers: (form) =>
    ajax({
      url: '/api/teams/members',
      type: 'POST',
      body: {
        teamId: form.teamId,
        members: form.users.map((userId) => ({
          userId,
        })),
      },
    }),

  /**
   * Removes team members from a team
   *
   * @param {object} form the removeTeamMembers form
   * @param {number} form.teamId the ID of the team to remove members from
   * @param {number[]} form.users the users IDs to remove
   */
  removeTeamMembers: (form) =>
    ajax({
      url: '/api/teams/members',
      type: 'DELETE',
      body: {
        teamId: form.teamId,
        members: form.users.map((userId) => ({
          userId,
        })),
      },
    }),

  /*************
   * Locations *
   *************/

  /**
   * Gets all the locations the user has access to
   */
  getLocations: () =>
    new Promise((resolve, reject) =>
      ajax({
        url: '/api/locations',
        type: 'GET',
      })
        .then((res) => {
          res.locations.forEach((location) => (location.id = location.locationId));
          resolve(res);
        })
        .catch((res) => reject(res)),
    ),
  /**
   * Gets one specific location
   */
  getLocation: (locationId) =>
    ajax({
      url: '/api/locations/' + locationId,
      type: 'GET',
    }),
  /**
   * Creates a location for the user's company
   *
   * @param {object} form the location creation form
   * @param {string} form.name the name of the location
   * @param {string} form.description the description of the location
   * @param {string} form.geofence a string-representation of a geofence
   */
  createLocation: (form) =>
    ajax({
      url: '/api/locations',
      type: 'POST',
      body: form,
    }),

  /**
   * Updates an existing location with new information
   *
   * @param {object} form the location update form
   * @param {number} form.id the ID of the location to update
   * @param {string} form.name the new name of the location
   * @param {string} form.description the new description of the location
   * @param {string} form.geofence a string-representation of a geofence
   */
  updateLocation: (form) =>
    ajax({
      url: `/api/locations/${form.id}`,
      type: 'PUT',
      body: form,
    }),

  /**
   * Removes a location from the user's company
   *
   * @param {number} locationId the ID of the location to remove
   */
  deleteLocation: (locationId) =>
    ajax({
      url: `/api/locations/${locationId}`,
      type: 'DELETE',
    }),

  /************
   * Missions *
   ************/

  /**
   * Gets all active missions in the company
   */
  getActiveCustomerMissions: () =>
    ajax({
      url: '/api/missions/all/active',
      type: 'GET',
    }),

  /**
   * Gets all closed missions in the company
   */
  getFinishedCustomerMissions: () =>
    ajax({
      url: '/api/missions/all/finished',
      type: 'GET',
    }),

  /**
   * Finishes a mission
   */
  finishMission: (id) =>
    ajax({
      url: `/api/missions/${id}/finish`,
      type: 'PUT',
    }),

  /**
   * Gets all subscribers of mission alarms in the company
   */
  getMissionAlarmSubscribers: () =>
    ajax({
      url: '/api/missions/subscribers',
      type: 'GET',
    }),

  setMissionAlarmSubscribers: (subscriberIds) =>
    ajax({
      url: '/api/missions/subscribers',
      type: 'PUT',
      body: {
        subscriberIds,
      },
    }),

  /**********************
   * LastKnownPositions *
   **********************/

  getLastKnownPositions: (userId) =>
    ajax({
      url: `/api/missions/positions/last/user/${userId}`,
      type: 'GET',
    }),

  /*************
   * Scenarios *
   *************/
  createScenario: (scenario) =>
    ajax({
      url: '/api/scenarios/customer',
      type: 'POST',
      body: scenario,
    }),

  getAllScenarios: () =>
    ajax({
      url: '/api/scenarios/customer',
      type: 'GET',
    }),

  getScenario: (scenarioId) =>
    ajax({
      url: `/api/scenarios/customer/${scenarioId}`,
      type: 'GET',
    }),

  getScenarioAttachmentUploadUrl: (scenarioId, extension) =>
    ajax({
      url: `/api/scenarios/customer/${scenarioId}/attachmentUploadUrl/${extension}`,
      type: 'GET',
    }),

  getScenarioAttachmentDeleteUrl: (scenarioId, name) =>
    ajax({
      url: `/api/scenarios/customer/${scenarioId}/attachmentDeleteUrl/${name}`,
      type: 'GET',
    }),

  updateScenario: (scenario) =>
    ajax({
      url: `/api/scenarios/customer/${scenario.id}`,
      type: 'PUT',
      body: scenario,
    }),

  deleteScenario: (scenario) =>
    ajax({
      url: `/api/scenarios/customer/${scenario.id}`,
      type: 'DELETE',
    }),

  updateScenarioSortIndex: (sortedScenarios) =>
    ajax({
      url: '/api/scenarios/customer/sortorder',
      type: 'PUT',
      body: sortedScenarios,
    }),

  sendScenario: (scenarioId, alertTitle, alertBody) =>
    ajax({
      url: `/api/scenarios/customer/${scenarioId}`,
      type: 'POST',
      body: {
        alertTitle,
        alertBody,
      },
    }),

  /*******************
   * ScenarioFolders *
   *******************/
  getAllScenarioFolders: () =>
    ajax({
      url: '/api/scenarioFolders/customer',
      type: 'GET',
    }),

  updateScenarioFolder: (folder) =>
    ajax({
      url: `/api/scenarioFolders/customer/${folder.id}`,
      type: 'PUT',
      body: folder,
    }),

  /**************
   * IotDevices *
   **************/
  generateIotDeviceCode: () =>
    ajax({
      url: `/api/iotDevices/code`,
      type: 'GET',
    }),

  getIotDevices: () =>
    ajax({
      url: '/api/iotDevices',
      type: 'GET',
    }),

  getIotDevice: (id) =>
    ajax({
      url: `/api/iotDevices/${id}`,
      type: 'GET',
    }),

  updateIotDevice: (device) =>
    ajax({
      url: `/api/iotDevices/${device.id}`,
      type: 'PUT',
      body: device,
    }),

  deleteIotDevice: (id) =>
    ajax({
      url: `/api/iotDevices/${id}`,
      type: 'DELETE',
    }),

  /************
   * Azure AD *
   ************/

  getAdEnabled: () =>
    ajax({
      url: `/api/azureAd/enabled`,
      type: 'GET',
    }),

  setAdEnabled: (enabled) =>
    ajax({
      url: `/api/azureAd/enabled`,
      type: 'PUT',
      body: {
        enabled,
      },
    }),

  generateAdKey: () =>
    ajax({
      url: `/api/azureAd/keys`,
      type: 'POST',
    }),

  getAdKeys: () =>
    ajax({
      url: `/api/azureAd/keys`,
      type: 'GET',
    }),

  deleteAdKey: (id) =>
    ajax({
      url: `/api/azureAd/keys/${id}`,
      type: 'DELETE',
    }),

  /*********
   * Misc. *
   *********/

  getGeoAddress: () =>
    ajax({
      url: 'https://europe-west1-teamalert-framweb.cloudfunctions.net/geolocation',
      external: true,
      type: 'GET',
    }),

  /**
   * Send registration to HubSpot
   *
   * @param {object} form the location update form
   * @param {string} form.companyName the name of the new company
   * @param {string} form.name the new name of the user
   * @param {string} form.email the new email
   * @param {string} form.phone the new phonenumber
   */
  registerInHubSpot: (form) =>
    ajax({
      url: 'https://api.hsforms.com/submissions/v3/integration/submit/7340301/ec0406d8-fad5-49a1-8d2c-40263ca99868',
      body: {
        fields: Object.keys(form).map((field) => ({
          name: field,
          value: form[field],
        })),
      },
      external: true,
      headers: {
        'Content-Type': 'application/json',
      },
    }),
};

export default api;
