<template>
  <div class="contents">
    <v-card v-if="latestAlerts.length > 0" class="card flex" elevation="3" outlined>
      <v-row justify="center" class="my-1">
        <v-card-title align-center
          >{{ $t('latestAlerts') }}
          <v-btn small text class="ml-5 btnShowAll" @click="openAlerts">
            {{ $t('showAll') }} ({{ activeAlertsCount }})
          </v-btn>
        </v-card-title>
      </v-row>
      <v-carousel
        v-model="alertSlider"
        :continuous="false"
        :show-arrows="false"
        hide-delimiter-background
        light
        delimiter-icon="fa-minus"
        height="auto"
      >
        <v-carousel-item v-for="(alert, i) in latestAlerts" :key="i">
          <AlertCard
            class="pt-0 pb-14 px-5"
            :alert="alert"
            :single-line="false"
            :display-user="true"
            @viewDetails="openAlert(alert)"
          />
        </v-carousel-item>
      </v-carousel>
    </v-card>
    <v-card v-else class="card pa-4 flex" elevation="3" outlined>
      <v-row justify="center">
        <v-card-title>{{ $t('noActiveAlerts') }}</v-card-title>
      </v-row>
      <p class="ma-4">{{ $t('emptyDashAlerts') }}</p>
      <v-img
        class="alert-image mx-auto my-4"
        src="@/assets/images/urgent-alert.svg"
        @click="newAlert"
      />
    </v-card>
  </div>
</template>

<script>
import AlertCard from '@/components/AlertCard';

export default {
  name: 'DashAlerts',

  components: {
    AlertCard,
  },
  props: {
    latestAlerts: {
      type: Array,
      required: true,
      default: () => [],
    },
    activeAlertsCount: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  data: () => ({
    alertSlider: 0,
  }),

  methods: {
    openAlerts() {
      this.$router.push('/alerts/');
    },
    openAlert(value) {
      this.$router.push('/alerts/' + value.id);
    },
    newAlert() {
      this.$router.push('/newAlert/');
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 12px;
}
.alert-image {
  filter: grayscale(100%);
  width: 140px;
  max-width: 140px;
  height: 140px;
  max-height: 140px;
  // margin: 0px 2px;
  cursor: pointer;
}
.v-carousel__controls {
  background: transparent !important;
}
.v-carousel__controls__item {
  color: #63898a !important;
}
.btnShowAll {
  position: absolute;
  right: 8px;
  margin-top: 3px;
}
</style>
