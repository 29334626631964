import appSettings from '@/appSettings';
import { warnLog } from '@/lib/util/logger';
import auth from '@/util/auth';

const urlBase = appSettings.teamAlertApi;
const skipTimeout = appSettings.skipTimeout;

const SESSION_EXPIRED = 440;

const getResponseHeader = (xhr, headerName) => {
  if (xhr.getAllResponseHeaders().toLowerCase().indexOf(headerName.toLowerCase()) >= 0) {
    return xhr.getResponseHeader(headerName);
  }

  return null;
};

/**
 * Perform an ajax call.
 *
 * @param  {object} opts            options
 * @param  {string} opts.url        the url to call (required)
 * @param  {string} opts.type       the HTTP type, e.g. 'GET' (default: 'POST')
 * @param  {object} opts.body       the request body (default: false)
 * @param  {Boolean} opts.external  whether or not this is a call to a third
 *                                    party resource, i.e. not the Hermes
 *                                    backend (default: false)
 * @param  {object} opts.headers    a map of headers to add to the request
 *
 * @return {Promise}                a Promise
 */
const ajax = (userOpts) => {
  const opts = Object.assign(
    {
      url: false,
      type: 'POST',
      body: false,
      external: false,
      timeout: skipTimeout ? 0 : 1000 * 10,
      headers: Object.assign({}, userOpts && userOpts.headers),
    },
    userOpts,
  );

  if (!opts.url) {
    return;
  }

  const r = new XMLHttpRequest();

  r.timeout = opts.timeout;

  const external = opts.external; // Accessing a resource other than Hermes
  const url = external ? opts.url : urlBase + opts.url;

  // Use client api headers only if accessing client api
  if (!external) {
    opts.headers['Content-Type'] = 'application/json';
    opts.headers['X-Version'] = '2.2';

    const jwt = auth.getJwt();
    if (jwt) {
      opts.headers['Authorization'] = `Bearer ${jwt}`;
    }

    const customerId = auth.getCustomerId();
    if (customerId) {
      opts.headers['CustomerId'] = customerId;
    }
  }

  if (opts.responseType) {
    r.responseType = opts.responseType;
  }

  r.open(opts.type, url, true);

  // Add all headers
  Object.keys(opts.headers).forEach((header) => {
    r.setRequestHeader(header, opts.headers[header]);
  });

  return new Promise((resolve, reject) => {
    const handleResponse = (success) => {
      try {
        // const jwt = r.getResponseHeader('Set-Token');
        const jwt = getResponseHeader(r, 'Set-Token');
        if (jwt) {
          auth.setJwt(jwt);
        }

        let response = r.response;

        if (response instanceof Blob) {
          resolve(response);
        }

        let jsonResponse;
        if (!response || response.length < 1) {
          if (r.status === 0) {
            warnLog('Assuming connection timed out.', r);
            jsonResponse = {
              errors: [
                {
                  message: 'Connection timed out. Did your network connection fall out?',
                },
              ],
            };
          } else if (!success) {
            warnLog('Unknown error.', r);
            jsonResponse = {
              errors: [
                {
                  message: 'Invalid URL',
                },
              ],
            };
          }
        } else {
          jsonResponse = JSON.parse(r.response);
        }

        if (success) {
          resolve(jsonResponse);
        } else {
          if (r && r.status === SESSION_EXPIRED) {
            // Session expired
            // Logout user
            auth.logout();
          } else {
            reject({
              status: r.status,
              statusText: r.statusText,
              response: jsonResponse,
            });
          }
        }
      } catch (e) {
        reject({
          status: r.status,
          statusText: r.statusText,
          response: r.response,
          jsError: e,
        });
      }
    };

    r.onreadystatechange = () => {
      if (r.readyState === XMLHttpRequest.DONE) {
        if (r.status >= 200 && r.status < 300) {
          handleResponse(true);
        } else {
          handleResponse(false);
        }
      }
    };

    if (opts.body) {
      try {
        r.send(JSON.stringify(opts.body));
      } catch (e) {
        r.send(opts.body || true);
      }
    } else {
      r.send();
    }
  });
};

export default ajax;
