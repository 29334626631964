import { translate as t } from '@/util/i18n';

const navItems = [
  {
    title: t('home'),
    icon: 'fa-home',
    path: '/',
  },
  {
    divider: false,
  },
  {
    title: t('newAlertPageTitle'),
    icon: 'fa-bell',
    path: '/newAlert',
    admin: true,
  },
  {
    title: t('newPositioningPageTitle'),
    icon: 'fa-map-marker-alt',
    path: '/newPositioning',
    admin: true,
  },
  {
    divider: true,
    admin: true,
  },
  {
    title: t('alertLogPageTitle'),
    icon: 'fa-th-list',
    path: '/alerts',
  },
  {
    title: t('positioningLogPageTitle'),
    icon: 'fa-list',
    path: '/positionings',
  },
  {
    title: t('missions'),
    icon: 'fa-running',
    path: '/missions',
    requiresFeature: 'mission',
    badge: 'activeMissions',
    admin: true,
  },
  {
    divider: true,
  },
  // {
  //   title: t('profile'),
  //   icon: 'fa-user-circle',
  //   path: '/profile'
  // },
  {
    title: t('company'),
    icon: 'fa-building',
    path: '/company',
    defaultMenuItem: 0,
    admin: true,
    menu: [
      {
        title: t('members'),
        icon: 'fa-user',
        path: '/company/members',
      },
      {
        title: t('teams'),
        icon: 'fa-users',
        path: '/company/teams',
      },
      {
        title: t('locations'),
        icon: 'fa-map-pin',
        path: '/company/locations',
      },
      {
        title: t('voice'),
        icon: 'fa-microphone',
        path: '/company/voice',
      },
      {
        title: t('azureAd'),
        icon: 'fab fa-windows',
        path: '/company/azureAd',
      },
      {
        title: t('settings'),
        icon: 'fa-sliders-h',
        path: '/company/settings',
      },
    ],
  },
  {
    title: t('automation'),
    icon: 'fa-magic',
    path: '/automation',
    defaultMenuItem: 0,
    requiresFeature: 'scenario',
    admin: true,
    menu: [
      {
        title: t('scenariosPageTitle'),
        icon: 'fa-file-check',
        path: '/automation/scenarios',
        requiresFeature: 'scenario',
        admin: true,
      },
      {
        title: t('iotDevices'),
        icon: 'fa-microchip',
        path: '/automation/iotDevices',
        requiresFeature: 'flicHub',
        admin: true,
      },
    ],
  },
];

export default navItems;
