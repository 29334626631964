<template>
  <div
    class="emblem ma-5 pa-5 mb-3"
    :class="[{ blueDark: alertType === 0 }, { alarm: alertType === 1 }, { alert: alertType === 2 }]"
    @click="$emit('click')"
  >
    <v-img class="alert-image" contain :src="emblem" />
  </div>
</template>

<script>
export default {
  name: 'RoundEmblem',

  props: {
    alertType: {
      type: Number,
      required: false,
      default: () => 1,
    },
  },
  computed: {
    emblem: function () {
      let filename = '';
      if (this.alertType === 2) {
        filename = 'urgent-alert-darkYellow';
      } else {
        filename = 'alarm-white';
      }

      return require(`@/assets/images/${filename}.svg`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/scss/settings/colors';

.emblem {
  padding: 16px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &.alert {
    background: linear-gradient(180deg, #ffce12 0%, #ffb119 100%);
  }
  &.alarm {
    background: linear-gradient(180deg, #cb1919 0%, #830808 100%);
  }
  &.blueDark {
    background: linear-gradient(180deg, #63898a 0%, #295152 100%);
  }
}

.alert-image {
  width: 60px;
  max-width: 60px;
  height: 60px;
  max-height: 60px;
  margin: 0px;
}
</style>
