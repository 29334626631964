<template>
  <v-dialog
    v-model="show"
    style="z-index: 2000"
    max-width="75%"
    scrollable
    @click:outside="doCancel"
    @keydown="onKeydown"
  >
    <v-img :src="src" :alt="alt" contain />
  </v-dialog>
</template>

<script>
export default {
  name: 'ImageDialog',

  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
  },

  data: () => ({
    show: null,
  }),

  methods: {
    doOpen() {
      this.show = true;
      this.$emit('show');
    },

    doCancel() {
      this.show = false;
      this.$emit('cancel');
    },

    onKeydown(e) {
      if (e.key === 'Escape') {
        this.doCancel();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-image {
  background: #262626;
}
</style>
