<template>
  <v-menu offset-y dark>
    <template #activator="{ on }">
      <v-btn icon :aria-label="$t('language')" v-on="on"><v-icon>fa-globe-europe</v-icon></v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(language, index) in languages"
        :key="index"
        :disabled="language.code === currentLanguage"
        @click="changeLanguage(language)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ language.name }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-img
            :src="require(`@/assets/images/flags/${language.flag}.svg`)"
            contain
            width="25px"
          />
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import i18n from '@/util/i18n';
export default {
  name: 'LanguageSwitcher',

  data: () => ({
    currentLanguage: null,
    languages: [],
  }),

  created() {
    this.languages = i18n.getLanguages();
    this.currentLanguage = i18n.getLanguage();
  },

  methods: {
    changeLanguage(language) {
      i18n.switchLanguage(language.code);
    },
  },
};
</script>

<style></style>
