import router from '@/router';
import is from '@/lib/util/is';
import { warnLog } from '@/lib/util/logger';

const JWT_KEY = 'jwt';
const CUSTOMER_ID_KEY = 'customerId';

const loginCallbacks = [];
const logoutCallbacks = [];
const setCustomerIdCallbacks = [];

const auth = {
  jwtKey: (() => JWT_KEY)(),

  customerIdKey: (() => CUSTOMER_ID_KEY)(),

  setCustomerId: (customerId) => {
    localStorage.setItem(CUSTOMER_ID_KEY, customerId);
    setCustomerIdCallbacks.forEach((customerIdCallback) => {
      customerIdCallback();
    });
  },

  getCustomerId: () => parseInt(localStorage.getItem(CUSTOMER_ID_KEY), 10),

  getJwt: () => localStorage.getItem(JWT_KEY),

  setJwt: (token) => localStorage.setItem(JWT_KEY, token),

  loggedIn: () => {
    const jwt = localStorage.getItem(JWT_KEY);
    return is.strNotEmpty(jwt) && jwt.length > 0;
  },

  login: () => {
    loginCallbacks.forEach((loginCallback) => {
      loginCallback();
    });
  },

  logout: () => {
    if (auth.loggedIn()) {
      localStorage.removeItem(JWT_KEY);
      router.push('/loggedOut');
      logoutCallbacks.forEach((logoutCallback) => {
        logoutCallback();
      });
    }
  },

  on: (event, callback) => {
    switch (event) {
      case 'login':
        loginCallbacks.push(callback);
        break;
      case 'logout':
        logoutCallbacks.push(callback);
        break;
      case 'setCustomerId':
        setCustomerIdCallbacks.push(callback);
        break;
      default:
        warnLog(`*** auth *** event ${event} is not supported`);
    }
  },
};

export default auth;
