<template>
  <v-app>
    <v-navigation-drawer v-if="showNav" v-model="navigation" app fixed clipped light>
      <div class="d-flex flex-column" style="height: 100%">
        <v-list dense class="pa-0 site-nav">
          <v-list-item class="pa-2">
            <v-list-item-content class="pa-0">
              <company-switcher :companies="companies" :current-company="currentCompany" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="py-4" link to="/profile">
            <profile-photo
              :url="user.profilePhoto"
              :user-name="user.name"
              :clickable="false"
              size="35px"
            />
            <v-list-item-content class="profile-item-content">
              <v-list-item-title class="title username">
                {{ user.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <template v-for="(navItem, index) in computedNavItems">
            <template v-if="navItem.path && navItem.show">
              <v-list-item
                v-if="!navItem.menu"
                :key="index"
                :disabled="navItem.requiresFeature && !hasFeature(navItem.requiresFeature)"
                :to="navItem.path"
              >
                <v-list-item-icon>
                  <v-icon>{{ navItem.icon }} fal fa-fw</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ navItem.title }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon v-if="navItem.badge > 0">
                  <span class="nav-item-badge">{{ navItem.badge }}</span>
                </v-list-item-icon>

                <v-list-item-icon v-if="navItem.beta" class="nav-item-label">
                  PREVIEW
                </v-list-item-icon>
              </v-list-item>

              <v-list-group
                v-else
                :key="index"
                :group="navItem.path"
                :disabled="navItem.requiresFeature && !hasFeature(navItem.requiresFeature)"
                no-action
                @click="openFirstMenuItem(navItem)"
              >
                <template #activator>
                  <v-list-item
                    class="pa-0"
                    :disabled="navItem.requiresFeature && !hasFeature(navItem.requiresFeature)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ navItem.icon }} fal fa-fw</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ navItem.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <template
                  v-for="(subNavItem, subNavItemIndex) in navItem.menu.filter(
                    (subNavItem) => subNavItem.show,
                  )"
                >
                  <v-list-item
                    :key="subNavItemIndex"
                    :disabled="
                      subNavItem.requiresFeature && !hasFeature(subNavItem.requiresFeature)
                    "
                    :to="subNavItem.path"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ subNavItem.icon }} fal fa-fw</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ subNavItem.title }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon v-if="subNavItem.badge > 0">
                      <span class="nav-item-badge">{{ navItem.badge }}</span>
                    </v-list-item-icon>
                    <v-list-item-icon v-if="subNavItem.beta" class="nav-item-label">
                      PREVIEW
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>

            <v-divider v-else-if="navItem.divider && navItem.show" :key="index" class="my-2" />
          </template>
        </v-list>
        <v-spacer />
        <v-subheader class="gray800--text overline">Build: {{ buildTimestamp }}</v-subheader>
      </div>
    </v-navigation-drawer>
    <v-app-bar app fixed dark color="primary" clipped-left>
      <v-app-bar-nav-icon
        v-if="!disableNav"
        :aria-label="$t('navigationMenu')"
        @click="navigation = !navigation"
      />
      <v-spacer />
      <router-link to="/" class="logo-home-link">
        <img
          class="my-3"
          src="@/assets/images/logo-teamalert-white6.svg"
          height="50%"
          width="auto"
          alt="TeamAlert"
        />
      </router-link>
      <v-spacer />
      <language-switcher />
      <div>
        <v-btn v-if="loggedIn" icon :aria-label="$t('logout')" @click="promptLogout">
          <v-icon>fa-sign-out-alt</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <!-- //TODO Check TeamAdmin menu -->

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { translate as t } from '@/util/i18n';
import router from '@/router';
import getUserProfile from '@/util/getUserProfile';
import auth from '@/util/auth';
import CompanySwitcher from '@/components/CompanySwitcher';
import LanguageSwitcher from '@/components/LanguageSwitcher';
import confirmDialog from '@/components/dialog/confirmDialog';
import { isAdmin } from './consts/roles';
import api from '@/util/api';
import navItems from '@/navItems';
import appSettings from './appSettings';
import ProfilePhoto from '@/components/ProfilePhoto.vue';

export default {
  name: 'App',

  components: {
    CompanySwitcher,
    LanguageSwitcher,
    ProfilePhoto,
  },

  data: () => ({
    buildTimestamp: appSettings.VUE_APP_BUILD_TIMESTAMP,
    navigation: null,
    disableNav: true,
    loggedIn: false,
    admin: true,
    user: {},
    currentCompany: null,
    companies: [],

    features: [],
    activeMissions: 0,

    updateActiveMissionsHandle: null,

    navItems,
  }),

  computed: {
    showNav() {
      return !this.disableNav && this.loggedIn;
    },

    computedNavItems() {
      return this.navItems.map((navItem) => {
        navItem.menu?.forEach((subNavItem) => {
          subNavItem.show = !subNavItem.admin || this.admin;
        });

        const show = !navItem.admin || this.admin;

        let badge = 0;
        if (navItem.badge) {
          badge = this[navItem.badge];
        }

        return {
          ...navItem,
          show,
          badge,
        };
      });
    },
  },

  created() {
    auth.on('setCustomerId', () => {
      this.loggedIn = true;
    });

    auth.on('logout', () => {
      this.loggedIn = false;
    });
  },

  mounted() {
    this.disableNav = Boolean(this.$route.meta.disableNav);

    if (auth.loggedIn()) {
      this.loggedIn = true;
      this.loadUserData();
    }

    if (!this.$signalR.isConnected()) {
      this.$signalR.start();
    }
    this.$root.$on('companyNameUpdated', () => {
      this.loadUserData(true);
    });

    router.beforeResolve((to, from, next) => {
      this.disableNav = Boolean(to.meta.disableNav);

      if (auth.loggedIn()) {
        this.loadUserData();
      }
      next();
    });
  },

  methods: {
    loadUserData(forceRefresh = false) {
      getUserProfile(forceRefresh).then((res) => {
        this.user = res.user;
        this.admin = isAdmin(res.user.role);

        this.features = res.features;

        if (this.features.find((f) => f.featureName === 'mission')) {
          this.subscribeToMissionChanges(res.customer.id);
        }

        this.currentCompany = {
          id: res.customer.customerId,
          name: res.customer.customerName,
        };

        this.companies = res.customers.map((customer) => ({
          id: customer.customerId,
          name: customer.customerName,
        }));
      });
    },

    hasFeature(featureName) {
      return !!this.features.find((f) => f.featureName === featureName);
    },

    openFirstMenuItem(navItem) {
      const defaultMenuItem = navItem.menu[navItem.defaultMenuItem];
      if (defaultMenuItem.path !== this.$route.path) {
        router.push(defaultMenuItem.path);
      }
    },

    subscribeToMissionChanges(customerId) {
      this.$signalR.on(`newMissionCustomerId:${customerId}`, () => {
        this.getActiveMissionsCount();
      });

      this.$signalR.on(`missionFinishedCustomerId:${customerId}`, () => {
        this.getActiveMissionsCount();
      });
    },

    getActiveMissionsCount() {
      api.getActiveCustomerMissions().then((res) => {
        this.activeMissions = res.missions.length;
      });
    },

    promptLogout() {
      confirmDialog(t('logout'), t('logoutDialogText'), t('cancel'), t('logout'))
        .open()
        .then((confirmed) => {
          if (confirmed) {
            api.logout().finally(() => {
              auth.logout();
            });
          }
        });
    },
  },
};
</script>

<style src="@/style/scss/webapp.scss" lang="scss"></style>

<style lang="scss">
.v-card__text,
.v-card__title {
  word-break: normal;
}

.clickable {
  cursor: pointer;
}
.contents {
  display: contents;
}
</style>

<style lang="scss" scoped>
@import '@/style/scss/settings/colors';

.logo-home-link {
  width: auto;
  height: 100%;
}
nav {
  background-color: #dbdbdb !important;
  color: $colorBlueDark;
}
.company-switcher {
  background-color: $colorBlueDark !important;
}
.site-nav {
  .v-list-item--link:hover,
  .v-list-group:not(.v-list-group--disabled):hover,
  .v-list-item--active,
  .v-list-group--active {
    background: #c9d6e1; // $colorGray800;
  }

  .v-list-group--active {
    .v-list-item--active {
      background: #c9d6e1; // $colorGray600;
      // color: $colorWhite;
    }

    & >>> .v-list-group__header__append-icon {
      color: $colorWhite;
    }
  }

  .theme--dark.v-list-item--active::before {
    opacity: 0;
  }
}

.nav-item-badge {
  display: block;
  background-color: $colorRedPrimary;
  font-size: 12px;
  width: 24px;
  height: 24px;
  line-height: 25px;
  text-align: center;
  border-radius: 15px;
}

.nav-item-label {
  position: absolute;
  font-size: 10px;
  right: 10px;
  top: -7px;
  color: $colorYellowDark;
}
.user-avatar {
  width: 35px !important;
  min-width: 35px !important;
  height: 35px !important;
  margin: 0px 0px 0px -1px;
}
.profile-item-content {
  margin-left: 11px;
}
.username {
  font-size: 1em !important;
}
</style>
