<template>
  <div class="d-flex flex-wrap align-start pa-2">
    <v-tooltip v-for="(attachment, index) in formattedAttachments" :key="index" bottom>
      <template #activator="{ on, attrs }">
        <div
          style="width: 100px"
          class="attachment text-center my-2 d-flex flex-column align-center justify-center"
          :class="{ clickable: preview }"
          v-bind="attrs"
          v-on="on"
          @click="openAttachment(attachment)"
        >
          <div style="width: 50px; height: 50px" class="d-flex justify-center align-center">
            <v-img
              v-if="isImage(attachment)"
              :src="attachment.presignedUrl"
              width="50px"
              height="50px"
            />

            <video-thumbnail
              v-else-if="isVideo(attachment)"
              :src="attachment.presignedUrl"
              width="50px"
              height="50px"
            />

            <v-icon v-else size="40px" :color="iconColor">
              fa-{{ getAttachmentFileIcon(attachment) }}
            </v-icon>
          </div>

          <div :class="iconColor + '--text'" class="text-body-2 attachment-name">
            {{ attachment.name ? attachment.name : attachment.url }}
          </div>
          <div :class="iconColor + '--text'" class="text-caption">
            ({{ $formatByteSize(attachment.size) }})
          </div>

          <div v-if="editable" class="remove-button">
            <v-btn fab elevation="1" width="25px" height="25px" @click.stop="remove(attachment)">
              <v-icon size="13px">fa-times</v-icon>
            </v-btn>
          </div>
        </div>
      </template>

      <div>
        {{ attachment.name }}
      </div>
    </v-tooltip>
    <div v-if="editable" class="text-center mt-2 mb-2" style="width: 100px">
      <v-btn fab small elevation="4" color="primary" @click="add"><v-icon>fa-plus</v-icon></v-btn>
    </div>
  </div>
</template>

<script>
import VideoThumbnail from '@/components/VideoThumbnail';
import imageDialog from './dialog/imageDialog';
import htmlDialog from './dialog/htmlDialog';
import getFileExtension from '@/util/getFileExtension';

const hasExtension = (uri, extensions) => {
  if (!uri) return false;

  const extension = getFileExtension(uri);
  if (!extension) return false;
  return extensions.includes(extension);
};

const hasMimeType = (uri, mimeTypes) => {
  if (!uri) return false;

  const match = mimeTypes.find((mimeType) => uri.startsWith(mimeType));
  return !!match;
};

export default {
  components: {
    VideoThumbnail,
  },

  props: {
    attachments: {
      type: Array,
      default: () => [],
    },

    editable: {
      type: Boolean,
      default: false,
    },

    preview: {
      type: Boolean,
      default: false,
    },

    iconColor: {
      type: String,
      default: 'gray800',
    },
  },

  data: () => ({
    editedAttachments: [],
    attachmentPopup: false,
  }),

  computed: {
    formattedAttachments() {
      return this.editedAttachments.map((a) => {
        const name = a.name ?? a.url;
        let shortName;
        const maxLength = 11;
        if (name.length > maxLength) {
          shortName = name.substring(0, maxLength) + '...';
        } else {
          shortName = name;
        }

        a.shortName = shortName;
        return a;
      });
    },
  },

  watch: {
    attachments: {
      immediate: true,
      handler(attachments) {
        this.editedAttachments = attachments ?? [];
      },
    },
  },

  created() {
    this.editedAttachments = this.attachments ?? [];
  },

  methods: {
    openAttachment(attachment) {
      if (!this.preview) return;

      if (this.isImage(attachment)) {
        imageDialog(attachment.presignedUrl, attachment.name ?? attachment.url).open();
      } else if (this.isVideo(attachment) || this.isAudio(attachment)) {
        let media;

        if (this.isVideo(attachment)) {
          media = document.createElement('video');
        } else {
          media = document.createElement('audio');
        }

        media.src = attachment.presignedUrl;
        media.title = attachment.name;
        media.controls = true;
        media.autoplay = true;
        media.style = 'display: block';
        htmlDialog(media).open();
      } else {
        const anchor = document.createElement('a');
        anchor.href = attachment.presignedUrl;
        anchor.target = '_blank';
        anchor.download = attachment.name ?? attachment.url;
        anchor.click();
      }
    },

    add() {
      this.$emit('add');
    },

    remove(attachment) {
      this.$emit('remove', attachment);
    },

    isImage(attachment) {
      if (!attachment?.presignedUrl) return false;

      if (hasMimeType(attachment.presignedUrl, ['data:image/'])) return true;

      return hasExtension(attachment.presignedUrl, [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'tif',
        'tiff',
        'webp',
        'heif',
        'heic',
        'svg',
        'raw',
      ]);
    },

    isVideo(attachment) {
      if (!attachment?.presignedUrl) return false;

      if (hasMimeType(attachment.presignedUrl, ['data:video/'])) return true;

      return hasExtension(attachment.presignedUrl, [
        'mp4',
        'mov',
        'avi',
        '3gp',
        'wmv',
        'flv',
        'm4v',
      ]);
    },

    isAudio(attachment) {
      if (!attachment?.presignedUrl) return false;

      if (hasMimeType(attachment.presignedUrl, ['data:audio/'])) return true;

      return hasExtension(attachment.presignedUrl, ['mp3', 'wav', 'aac', 'm4a']);
    },

    isPdf(attachment) {
      if (!attachment?.presignedUrl) return false;

      if (hasMimeType(attachment.presignedUrl, ['data:application/pdf'])) return true;

      return hasExtension(attachment.presignedUrl, ['pdf']);
    },

    isWord(attachment) {
      if (!attachment?.presignedUrl) return false;

      if (
        hasMimeType(attachment.presignedUrl, [
          'data:application/msword',
          'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ])
      )
        return true;

      return hasExtension(attachment.presignedUrl, ['doc', 'docx']);
    },

    isPowerpoint(attachment) {
      if (!attachment?.presignedUrl) return false;

      if (hasMimeType(attachment.presignedUrl, ['data:application/vnd.ms-powerpoint'])) return true;

      return hasExtension(attachment.presignedUrl, ['ppt', 'pptx']);
    },

    isExcel(attachment) {
      if (!attachment?.presignedUrl) return false;

      if (
        hasMimeType(attachment.presignedUrl, [
          'data:application/vnd.ms-excel',
          'data:text/csv',
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ])
      )
        return true;

      return hasExtension(attachment.presignedUrl, ['xls', 'xlsx']);
    },

    isText(attachment) {
      if (!attachment?.presignedUrl) return false;

      if (hasMimeType(attachment.presignedUrl, ['data:text/rtf', 'data:text/plain'])) return true;

      return hasExtension(attachment.presignedUrl, ['txt', 'rtf']);
    },

    getAttachmentFileIcon(attachment) {
      if (this.isAudio(attachment)) return 'file-audio';

      if (this.isPdf(attachment)) return 'file-pdf';

      if (this.isWord(attachment)) return 'file-word';

      if (this.isPowerpoint(attachment)) return 'file-powerpoint';

      if (this.isExcel(attachment)) return 'file-excel';

      if (this.isText(attachment)) return 'file-lines';

      return 'file';
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment {
  position: relative;

  .attachment-name {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .remove-button {
    position: absolute;
    top: -12px;
    right: 12px;
  }
}
</style>
