import is from './../util/is';
import { errLog } from '../util/logger';

/**
 * Creates an element
 * @param  {string} tag                  the element's tag name, e.g. 'div'
 * @param  {string} className            the element's className, e.g. 'title'
 *                                       (optional)
 * @param  {string|HTMLElement} inner the element's innerHTML (optional)
 * @param  {Object} attrs                additional attributes (optional)
 * @return {HTMLElement}              a HTMLElement as specified if tag is
 *                                       a string, else a <div> HTMLElement
 */
export const cr = (tag, className, inner, attrs) => {
  if (typeof tag !== 'string') {
    tag = 'div';
  }
  const el = document.createElement(tag);

  if (className) {
    el.className = className;
  }

  if (inner !== undefined && inner !== null) {
    if (is.node(inner)) {
      ap(el, inner);
    } else if (!is.object(inner) && !is.function(inner)) {
      el.innerHTML = inner;
    }
  }

  if (attrs) {
    Object.assign(el, attrs);
  }

  return el;
};

const flatArray = (array, result) => {
  if (!is.array(result)) {
    result = [];
  }
  array.forEach((child) => {
    if (is.array(child)) {
      flatArray(child, result);
    } else {
      result.push(child);
    }
  });
  return result;
};

const addChild = (parent, before, children) => {
  const add = (child) => {
    try {
      if (before) {
        parent.insertBefore(child, parent.firstChild);
      } else {
        parent.appendChild(child);
      }
    } catch (e) {
      errLog(e);
    }
  };
  if (parent) {
    flatArray(children).forEach((child) => {
      if (is.defined(child)) {
        if (is.string(child) || is.number(child)) {
          add(cr('span', null, child));
        } else {
          add(child);
        }
      }
    });
  }
  return parent;
};

/**
 * Prepend child elements to a parent element.
 * The first param is the parent, and any subsequent params are children
 * @param  {HTMLElement} parent the element to append to
 * @return {HTMLElement}        the parent
 */
export const pp = (parent, ...children) => addChild(parent, true, children);

/**
 * Append child elements to a parent element.
 * The first param is the parent, and any subsequent params are children
 * @param  {HTMLElement} parent the element to append to
 * @return {HTMLElement}        the parent
 */
export const ap = (parent, ...children) => addChild(parent, false, children);

/**
 * Removes an element from the DOM.
 * @param  {HTMLElement} target the element to remove
 * @return {HTMLElement}        the target
 */
export const rm = (target) => {
  if (is.defined(target) && is.defined(target.parentNode)) {
    target.parentNode.removeChild(target);
  }
  return target;
};

/**
 * Replaces the target in the node tree with the replacement.
 * @param  {HTMLElement} target      the target to replace
 * @param  {HTMLElement} replacement the replacement
 *
 * @returns {HTMLElement} the replacement
 */
export const rp = (target, replacement) => {
  if (is.defined(target) && is.defined(replacement)) {
    const parent = target.parentNode;
    if (is.defined(parent)) {
      parent.replaceChild(replacement, target);
    }
  }
  return replacement;
};

/**
 * Adds an event listnener
 * @param {HTMLElement|HTMLElement[]} target the target or targets which the event is triggered by
 * @param {string|string[]} type the event type, e.g. 'click' or 'input'
 * @param {Function} fn the function to call when the event is triggered
 *
 * @return {HTMLElement}        the target
 */
export const on = (target, type, fn) => {
  let targetList = target;
  if (!is.array(targetList)) {
    targetList = [targetList];
  }

  let eventList = type;
  if (!is.array(eventList)) {
    eventList = [eventList];
  }

  targetList.forEach((target) => {
    const handler = (event) => {
      fn(event, target);
    };
    eventList.forEach((type) => {
      target.addEventListener(type, handler);
    });
  });

  return target;
};

/**
 * Sets the entire classname
 * @param  {HTMLElement} target    the target to change the className of
 * @param  {string}         className the className to set
 * @return {HTMLElement}           the target
 */
export const sc = (target, className) => {
  target.className = className;
  return target;
};

/**
 * Adds the className if not already present
 * @param  {HTMLElement} target    the target to add the className to
 * @param  {string}         className the className to add
 * @return {HTMLElement}           the target
 */
export const ac = (target, className) => {
  if (!is.defined(target.className) || !is.inside(target.className, className)) {
    if (is.strNotEmpty(target.className)) {
      target.className += ' ' + className;
    } else {
      target.className = className;
    }
  }

  return target;
};

/**
 * Replaces the className
 * @param  {HTMLElement} target        the target to change the className of
 * @param  {string}         fromClassName the className to replace
 * @param  {string}         toClassName   the className to insert
 * @return {HTMLElement}               the target
 */
export const rc = (target, fromClassName, toClassName) => {
  if (is.inside(target.className, fromClassName)) {
    target.className = target.className.replace(fromClassName, '').trim();
  }
  return ac(target, toClassName);
};

/**
 * Deletes the className
 * @param  {HTMLElement} target     the target to remove the className of
 * @param  {string|string[]} className the className or classNames to remove
 * @return {HTMLElement}            the target
 */
export const dc = (target, className) => {
  if (!is.array(className)) {
    className = [className];
  }

  className.forEach((classNameN) => {
    target.className = target.className.replace(classNameN, '').trim();
  });
};

/**
 * Adds CSS styling
 * @param  {HTMLElement} target the target to add styling to
 * @param  {object}         styles the CSS style, as JSON
 * @return {HTMLElement}        the target
 */
export const st = (target, styles) => {
  Object.assign(target.style, styles);
  return target;
};

/**
 * Triggered on document ready
 * @param  {Function} fn the function to call when the document is ready
 */
export const ready = (fn) => {
  on(document, 'DOMContentLoaded', fn);
};

/**
 * Searches for an element with a query selector
 * @param  {string} search        The query selector statement
 * @param  {HTMLElement} root  The root element to search from
 * @return {NodeList}             The first result of the query selector
 */
export const q = (search, root) => {
  if (is.defined(root)) {
    return root.querySelector(search);
  } else {
    return document.querySelector(search);
  }
};

/**
 * Searches for elements with a query selector
 * @param  {string} search        The query selector statement
 * @param  {HTMLElement} root  The root element to search from
 * @return {NodeList}             The search results
 */
export const qa = (search, root) => {
  if (is.defined(root)) {
    return root.querySelectorAll(search);
  } else {
    return document.querySelectorAll(search);
  }
};
