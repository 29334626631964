import { cr, ap, on, dc } from './dom';
import i from './i';

/**
 * Displays a loading icon that disappears when the element is loaded
 *
 * @param {HTMLBodyElement|HTMLFrameElement|HTMLFrameSetElement|HTMLIFrameElement|HTMLImageElement|HTMLInputElement|HTMLLinkElement|HTMLScriptElement|HTMLStyleElement} element the element to display loading
 * @param {string} className the class name of the element wrapper
 */
const asyncElement = (element, className) => {
  className = className ? ' ' + className : '';
  const elementWrapper = cr('div', 'c-async loading' + className);
  const asyncLoadingIcon = ap(cr('div', 'c-async-loading-icon'), i('cloud-download-alt'));

  on(element, ['load', 'error'], () => dc(elementWrapper, 'loading'));

  return ap(elementWrapper, element, asyncLoadingIcon);
};

export default asyncElement;
