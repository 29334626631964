<template>
  <v-dialog
    v-model="show"
    eager
    width="auto !important"
    max-width="400px"
    @click:outside="doCancel"
    @keydown="onKeydown"
  >
    <div ref="elementToAppendTo" />
  </v-dialog>
</template>

<script>
export default {
  name: 'ImageDialog',

  props: {
    htmlElement: {
      type: HTMLElement,
      required: true,
    },
  },

  data: () => ({
    show: null,
    appended: false,
  }),

  mounted() {
    this.$refs.elementToAppendTo.append(this.htmlElement);
  },

  methods: {
    doOpen() {
      this.show = true;
      this.$emit('show');
    },

    doCancel() {
      this.show = false;
      this.$emit('cancel');
    },

    onKeydown(e) {
      if (e.key === 'Escape') {
        this.doCancel();
      }
    },
  },
};
</script>
