export const redDark = '#940012';
export const redPrimary = '#cc2e38';
export const redLight = '#ff6362';

export const yellowDark = '#b89700';
export const yellowPrimary = '#efc716';
export const yellowLight = '#fdf4b7';

export const blueDark = '#001d33';
export const bluePrimary = '#00b1eb';
export const blueLight = '#ddf1fd';

export const white = '#ffffff';

export const gray900 = '#4a4a4a';
export const gray800 = '#484848';
export const gray600 = '#757575';
export const gray400 = '#bdbdbd';
export const gray200 = '#f0f0f0';
export const gray100 = '#f5f5f5';

export const gray100transparent20 = 'rgba(245, 245, 245, 0.2)';
export const gray900transparent50 = 'rgba(33, 33, 33, 0.5)';

export const deliveredApp = '#07735F'; //'#001d33';
export const receivedinapp = '#07735F';
export const received = '#07735F';
export const deliveredSms = '#0D8771'; //'#00b1eb';
export const receivedassms = '#0D8771'; //'#003761';
export const failed = '#dc4351';
export const fallback = '#FF7E00';
export const fallbacksms = '#FF7E00';
export const sent = '#e4e4e4';
export const processing = '#e4e4e4';
export const outOfTarget = '#bbccdb';
export const outoftarget = '#bbccdb';
