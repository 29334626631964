import appSettings from '@/appSettings';
import * as signalR from '@microsoft/signalr';

const connection = new signalR.HubConnectionBuilder()
  .withUrl(appSettings.teamAlertApi + '/eventHub')
  .withAutomaticReconnect()
  .build();

connection.isConnected = () => connection.state === 'Connected';

export default connection;
