<template>
  <v-card class="scenarios-card" elevation="3" outlined>
    <v-slide-group multiple show-arrows class="d-flex justify-space-around ma-2">
      <v-row justify="center" class="my-1">
        <v-slide-item v-if="scenarios.length === 0">
          <div>
            <round-emblem :alert-type="0" @click="newAlert()" />
            <span class="d-flex justify-center align-center">{{ $t('createScenario') }}</span>
          </div>
        </v-slide-item>
        <v-slide-item v-for="(scenario, i) in scenarios" :key="i" class="d-flex flex-column">
          <div>
            <round-emblem :alert-type="scenario.alertType" @click="openDialog(scenario)" />
            <span class="scenario-title d-inline-block text-truncate">{{ scenario.name }}</span>
          </div>
        </v-slide-item>
      </v-row>
    </v-slide-group>
    <v-dialog v-model="scenarioDialog" width="500">
      <scenario
        :key="currentScenario.id"
        :data="currentScenario"
        :is-admin="false"
        :all-teams="allTeams"
        :has-cancel-button="true"
        @cancel="scenarioDialog = false"
        @start="sendScenario"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import RoundEmblem from '@/components/RoundEmblem.vue';
import Scenario from '@/views/scenarios/Scenario';
import api from '@/util/api';
import confirmDialog from '@/components/dialog/confirmDialog';
import router from '@/router';
import errorMessage from '@/util/errorMessage';
export default {
  name: 'DashScenarios',
  components: {
    RoundEmblem,
    Scenario,
  },
  props: {
    scenarios: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    scenarioDialog: false,
    currentScenario: {},
    allTeams: [],
  }),
  mounted() {
    this.getAllTeams();
  },
  methods: {
    async getAllTeams() {
      const response = await api.getTeams();
      this.allTeams = response.teams;
    },
    openDialog(scenario) {
      this.currentScenario = scenario;
      this.scenarioDialog = true;
    },
    newAlert() {
      this.$router.push('/automation/scenarios/');
    },
    async sendScenario(scenario) {
      const confirmed = await confirmDialog(
        this.$t('startScenario'),
        this.$t('startScenarioConfirm') + ' "' + scenario.name + '" ?',
        this.$t('cancel'),
        this.$t('start'),
      ).open();

      if (confirmed) {
        const alertData = scenario.alertData;

        try {
          const res = await api.sendScenario(scenario.id, alertData.title, alertData.body);

          if (res.alertId) {
            // Timeout to give the alert time to copy attachments
            setTimeout(() => {
              router.push(`/alerts/${res.alertId}`);
            }, 500);
          } else {
            errorMessage(this.$t('sendAlertError'), this.$t('sendAlertErrorMsg'), res);
          }
        } catch (error) {
          errorMessage(this.$t('sendAlertError'), this.$t('sendAlertErrorMsg'), error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scenarios-card {
  border-radius: 12px;
}
.scenario-title {
  max-width: 140px;
  align-self: center;
}
</style>

<style lang="scss" scoped>
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 10px !important;
}
</style>
