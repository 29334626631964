import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import '@/plugins/v-mask';
import '@/plugins/vue-i18n-plugin';
import '@/plugins/vue-format-byte-size';
import '@/plugins/vue-signalr';
import '@/plugins/vue-linkify';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import { ProgressPlugin } from 'bootstrap-vue';
import { TooltipPlugin } from 'bootstrap-vue';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.config.productionTip = false;
Vue.use(ProgressPlugin);
Vue.use(TooltipPlugin);
new Vue({
  router,
  vuetify,
  ProgressPlugin,
  TooltipPlugin,
  render: (h) => h(App),
}).$mount('#app');
