<template>
  <v-card class="bar-card pa-3" elevation="3" outlined>
    <Bar
      :chart-data="chartData"
      :chart-options="chartOptions"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </v-card>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy';
import 'chartjs-adapter-moment';
import moment from 'moment';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeSeriesScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, TimeSeriesScale);

export default {
  name: 'DashBarChart',
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 90,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data: (self) => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: true,
      beginAtZero: true,

      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false,
          callbacks: {
            title: function (context) {
              let date = moment(context[0].label, 'MMM DD YYYY hh:mm:ss');
              return date.format('MMMM DD YYYY');
            },
          },
        },
        legend: {
          display: false,
        },
      },

      scales: {
        x: {
          display: true,
          type: 'time',
          time: {
            parser: (xValue) => moment(xValue),
            unit: 'month',
          },
          grid: {
            display: false,
          },
        },
        y: {
          title: {
            display: true,
            text: self.$t('alerts'),
            font: {
              size: 14,
            },
          },
          precision: 0,
          suggestedMax: 10,
        },
      },
    },
  }),
};
</script>

<style lang="scss" scoped>
.bar-card {
  border-radius: 12px;
  height: 100%;
}
</style>
