export const SUPERADMIN = 'superAdmin';

export const ADMIN = 'admin';

export const BILLING = 'billing';

export const USER = 'user';

export const isAdmin = (role) => role === ADMIN || role === SUPERADMIN;

export const isUser = (role) => role === USER;

export const ROLES = [ADMIN, BILLING, USER];
